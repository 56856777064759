import { Box, Container, Divider, Typography } from '@mui/material'

import Footer from 'pages/home/components/Footer'

export const PrivacyPolicy: React.FC = () => {
  return (
    <Box className="mt-20">
      {/* Header Section */}
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" gutterBottom>
            Haulistic Privacy Policy
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Effective Date: 6 August 2024
          </Typography>
          <Divider />
          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              1. Introduction
            </Typography>
            <Typography paragraph>
              This Privacy Policy explains how CARTCREST E-MARKET SOLUTIONS
              PRIVATE LIMITED {`("we," "our," or "us")`} collects, uses, and
              protects your personal information through Haulistic. By using our
              Platform, you agree to the practices described in this policy.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              2. Information We Collect
            </Typography>
            <Typography paragraph>
              <strong>Personal Information:</strong> We collect information such
              as your name, email address, phone number, and payment information
              when you register, sell products, or make purchases on the
              Platform.
              <br />
              <strong>Business Information:</strong> For Sellers, we collect
              information about your Instagram account, product listings,
              inventory, and transaction history.
              <br />
              <strong>Usage Data:</strong> We collect data on how you use the
              Platform, including IP addresses, browser types, pages visited,
              and actions taken within the dashboard.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              3. How We Use Your Information
            </Typography>
            <Typography paragraph>
              <strong>To Provide Services:</strong> We use your personal and
              business information to facilitate transactions, manage product
              listings, integrate payments, and support delivery services.
              <br />
              <strong>To Improve the Platform:</strong> We analyze usage data to
              enhance the functionality, security, and user experience of the
              Platform.
              <br />
              <strong>Marketing:</strong> With your consent, we may use your
              information to send promotional materials and offers related to
              Haulistic’s services.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              4. Sharing Your Information
            </Typography>
            <Typography paragraph>
              <strong>Third-Party Service Providers:</strong> We may share your
              information with third-party providers who assist with payment
              processing, data analysis, and delivery services.
              <br />
              <strong>Legal Requirements:</strong> We may disclose your
              information to comply with legal obligations, court orders, or to
              protect our rights and safety.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              5. Data Security
            </Typography>
            <Typography paragraph>
              We implement appropriate technical and organizational measures to
              protect your personal and business information from unauthorized
              access, use, or disclosure.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              6. Data Retention
            </Typography>
            <Typography paragraph>
              We retain your personal and business information for as long as
              necessary to fulfill the purposes outlined in this Privacy Policy,
              unless a longer retention period is required by law.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              7. Your Rights
            </Typography>
            <Typography paragraph>
              <strong>Access and Correction:</strong> You can access and update
              your personal and business information by logging into your
              account.
              <br />
              <strong>Deletion:</strong> You can request the deletion of your
              personal and business information, subject to certain legal and
              contractual obligations.
              <br />
              <strong>Opt-Out:</strong> You can opt-out of receiving marketing
              communications by following the instructions in those
              communications.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              8. Children&apos;s Privacy
            </Typography>
            <Typography paragraph>
              Haulistic does not knowingly collect personal information from
              children under 13. If we learn that we have collected such
              information, we will take steps to delete it.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              9. Changes to This Privacy Policy
            </Typography>
            <Typography paragraph>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new policy on the
              Platform.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              10. Contact Us
            </Typography>
            <Typography paragraph>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at{' '}
              <a href="admin@haulistic.io">admin@haulistic.io</a>.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  )
}
