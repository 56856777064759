import { useState, useEffect, FC } from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { scaleLinear } from 'd3-scale'
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps'

import geoData from '../data/world.json'

type HeatmapData = {
  [key: string]: number
}

interface MapChartProps {
  data: HeatmapData
}

const MapChart: FC<MapChartProps> = ({ data }): JSX.Element => {
  const [zoom, setZoom] = useState(1)
  const [countryData, setCountryData] = useState<HeatmapData>({})
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null)
  const [hoveredValue, setHoveredValue] = useState<number | null>(null)
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })

  useEffect(() => {
    setCountryData(data)
  }, [data])

  const handleZoomIn = (): void => {
    setZoom(zoom * 2)
  }

  const handleZoomOut = (): void => {
    setZoom(zoom / 2)
  }

  const baseColor = '#00897B'

  const getColorScale = scaleLinear<string>()
    .domain([0, Math.max(...Object.values(countryData))])
    .range(['#D6D6DA', baseColor])

  const getColor = (value?: number): string => {
    return value ? getColorScale(value) : '#D6D6DA'
  }

  const handleMouseMove = (event: React.MouseEvent): void => {
    setMousePosition({ x: event.clientX, y: event.clientY })
  }

  return (
    <Box
      position={'relative'}
      onMouseMove={handleMouseMove}
      sx={{ overflow: 'visible' }}
    >
      <Box sx={{ position: 'absolute', top: 10, left: 10 }}>
        <IconButton onClick={handleZoomIn} color="primary">
          <AddIcon />
        </IconButton>
        <IconButton onClick={handleZoomOut} color="primary">
          <RemoveIcon />
        </IconButton>
      </Box>

      <Box>
        <ComposableMap
          projectionConfig={{
            scale: 140,
          }}
          width={800}
          height={350}
          style={{ width: '100%', height: 'auto' }}
        >
          <ZoomableGroup zoom={zoom}>
            <Geographies geography={geoData}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const countryName = geo.properties.name
                  const value = countryData[countryName]

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={getColor(value)}
                      stroke="#FFFFFF"
                      style={{
                        hover: {
                          cursor: 'pointer',
                        },
                      }}
                      onMouseEnter={() => {
                        setHoveredCountry(countryName)
                        setHoveredValue(value || 0)
                      }}
                      onMouseLeave={() => {
                        setHoveredCountry(null)
                        setHoveredValue(null)
                      }}
                    />
                  )
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </Box>

      {hoveredCountry && hoveredValue !== null && (
        <Box
          sx={{
            position: 'fixed',
            left: mousePosition.x + 10,
            top: mousePosition.y + 10,
            backgroundColor: '#fff',
            padding: '5px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 1000,
          }}
        >
          <Typography variant="caption">
            {hoveredCountry}: {hoveredValue}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default MapChart
