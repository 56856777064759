import { useState, useEffect, useRef } from 'react'

import { getPendingInvitations } from 'services/account'
import { Invitation } from 'types/account'

import PendingInvitations from './components/PendingInvitations'
import Settings from './components/Settings'

const AccountSettings = (): JSX.Element => {
  const [pendingInvitationList, setPendingInvitationList] = useState<
    Invitation[]
  >([])
  const fetchInvitationsCalled = useRef(false)

  async function fetchPendingInvites(): Promise<void> {
    const result = await getPendingInvitations()

    if (result.status === 'successful' && result.data) {
      setPendingInvitationList(result.data)
    }
  }

  useEffect(() => {
    if (!fetchInvitationsCalled.current) {
      fetchPendingInvites()
      fetchInvitationsCalled.current = true
    }
  }, [])
  return (
    <div>
      <Settings />
      {pendingInvitationList.length > 0 && (
        <PendingInvitations inviteList={pendingInvitationList} />
      )}
    </div>
  )
}

export default AccountSettings
