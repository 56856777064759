import { createContext, useState, useEffect, ReactNode } from 'react'

import { confirmRegistration, getUser, login, logout } from 'services/auth'
import { getOrganization } from 'services/organization'
import { AuthContextType, AuthStatus, User } from 'types/auth'
import { Organization } from 'types/organization'
import {
  clearAuthCookies,
  getAuthCookie,
  getToken,
  setAuthCookies,
} from 'utils/authCookies'

const defaultContext: AuthContextType = {
  isAuthenticated: false,
  accessToken: null,
  signIn: () => {},
  signOut: () => {},
  confirmSignUp: () => {},
  user: null,
  organization: null,
  organizationId: null,
  changeOrganization: () => {},
  updateOrganizationData: () => {},
}

export const AuthContext = createContext<AuthContextType>(defaultContext)

export const AuthProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const accessToken = getAuthCookie('AccessToken')
  const [user, setUser] = useState<User | null>(null)
  const [organizationId, setOrganizationId] = useState<number | null>(null)
  const [organization, setOrganization] = useState<Organization | null>(null)

  const [authStatus, setAuthStatus] = useState<AuthStatus>({
    isAuthenticated: accessToken != null,
    accessToken,
  })

  useEffect(() => {
    async function setAccessToken(): Promise<void> {
      const accessToken = await getToken('AccessToken')

      if (accessToken) {
        setAuthStatus({ isAuthenticated: true, accessToken })
      }
    }
    setAccessToken()
  }, [])

  useEffect(() => {
    const setUserData = async (): Promise<void> => {
      const userData = await getUser()
      if (userData) setUser(userData)

      if (userData && userData.organizations?.length > 0) {
        const defaultOrg = localStorage.getItem('defaultOrg')

        if (defaultOrg && defaultOrg !== '') {
          const org = userData.organizations.find(
            (obj) => obj.organizationId === Number(defaultOrg)
          )

          if (org) {
            setOrganizationId(org.organizationId)
            return
          }
        }
        setOrganizationId(userData.organizations[0].organizationId)
        localStorage.setItem(
          'defaultOrg',
          userData.organizations[0].organizationId.toString()
        )
      }
    }

    if (authStatus.isAuthenticated) setUserData()
  }, [authStatus])

  useEffect(() => {
    const getOrg = async (organizationId: number): Promise<void> => {
      const result = await getOrganization(organizationId)
      if (result.status === 'successful' && result.data)
        setOrganization(result.data)
    }
    if (organizationId) getOrg(organizationId)
  }, [organizationId])

  const signIn = async (email: string, password: string): Promise<void> => {
    const result = await login({ email, password })
    if (result.status === 'successful') {
      const authData = result.data
      setAuthCookies(authData)
      setAuthStatus({
        isAuthenticated: true,
        accessToken: authData.AccessToken,
      })
    }
  }

  const confirmSignUp = async (
    email: string,
    password: string,
    confirmationCode: string
  ): Promise<void> => {
    const result = await confirmRegistration({
      email,
      password,
      confirmationCode,
    })
    if (result.status === 'successful') {
      const authData = result.data
      setAuthCookies(authData)
      setAuthStatus({
        isAuthenticated: true,
        accessToken: authData.AccessToken,
      })
    }
  }

  const signOut = async (): Promise<void> => {
    const result = await logout(accessToken ?? '')
    if (result.status === 'successful') {
      clearAuthCookies()
      setAuthStatus({ isAuthenticated: false, accessToken: null })
    }
  }

  const changeOrganization = (id: number | null): void => {
    if (typeof id === 'number') {
      setOrganizationId(id)
      localStorage.setItem('defaultOrg', id.toString())
    } else {
      setOrganizationId(null)
      setOrganization(null)
      localStorage.removeItem('defaultOrg')
    }
  }

  const updateOrganizationData = (org: Organization): void => {
    setOrganization(org)
  }

  return (
    <AuthContext.Provider
      value={{
        ...authStatus,
        signIn,
        signOut,
        confirmSignUp,
        user,
        organization,
        organizationId,
        changeOrganization,
        updateOrganizationData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
