import { useEffect, useState } from 'react'

import { Box } from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { TabHeader } from 'pages/dashboard/components/TabHeader'
import { getOrders } from 'services/orders'
import { Order } from 'services/orders/index.type'

import OrdersList from '../components/OrdersList'

const OrdersPage = (): JSX.Element => {
  const { organizationId, organization } = useAuth()
  const [list, setList] = useState<Order[]>([])

  useEffect(() => {
    async function intialFetch(): Promise<void> {
      const result = await getOrders({ orgId: organizationId as number })
      if (result?.total && result.total > 0) {
        result?.orders ? setList(result.orders) : setList([])
      }
    }
    if (organizationId) {
      intialFetch()
    }
  }, [organizationId])

  if (organization == null) {
    return <SetupStorePrompt />
  }

  return (
    <div>
      <div className="mb-5">
        {list.length === 0 && (
          <div>
            <Box ml={2}>
              <p className="text-xl font-bold">Orders</p>
            </Box>
            <Card>
              <p className="text-sm text-black-800 mb-4 text-center">
                Track and manage all customer orders from one convenient place.
                This is where you can manage all customer orders, update
                statuses, and efficiently handle returns and cancellations,
                ensuring a streamlined order processing experience.
              </p>
            </Card>
          </div>
        )}

        {list.length > 0 && (
          <>
            <TabHeader title="Orders" />
            {organizationId && <OrdersList orgId={organizationId} />}
          </>
        )}
      </div>
    </div>
  )
}
export default OrdersPage
