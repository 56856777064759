import { FC } from 'react'

import { Button } from '@mui/material'

interface CategoryEditBtnProps {
  onClick: () => void
}

const CategoryEditBtn: FC<CategoryEditBtnProps> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      className="category-edit-btn"
      size="small"
      sx={{
        position: 'absolute',
        bottom: 10,
        left: 0,
        width: '100%',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        backgroundColor: 'black',
        borderRadius: 0,
        '&:hover': { backgroundColor: 'black' },
      }}
      onClick={onClick}
    >
      Edit
    </Button>
  )
}

export default CategoryEditBtn
