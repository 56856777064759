import { FC, useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import Label from 'components/forms/Label'
import TextCollapse from 'pages/dashboard/components/TextCollapse'
import { getCategories } from 'services/products'
import { Product } from 'services/products/index.types'
import { Category } from 'types/product'
import { dateTimeFormat } from 'utils/dateFormat'
interface BasicDetailsProps {
  product: Product
  onUpdate?: () => void
}

const BasicDetails: FC<BasicDetailsProps> = ({ product }) => {
  const [names, setNames] = useState<string | null>(null)

  useEffect(() => {
    const category = product.categoryIds
    const get = async (orgId: number): Promise<void> => {
      const result = await getCategories(orgId)
      if (result?.status === 'successful' && category && category.length > 0) {
        const data = result.data.filter((el: Category) =>
          category.includes(el.id)
        )
        const categoryNames = data.map((el: Category) => el.name).join(', ')
        setNames(categoryNames)
      } else if (category?.length === 0) {
        setNames(null)
      }
    }
    get(product.organizationId)
  }, [product])

  const colorVariantsLength =
    product.variants.length > 0
      ? product.variants.filter((v) => v.type === 'color').length
      : 0
  const sizeVariantsLength =
    product.variants.length > 0
      ? product.variants.filter((v) => v.type === 'size').length
      : 0

  const TotalVariant =
    colorVariantsLength > 0 && sizeVariantsLength > 0
      ? `${colorVariantsLength} Colors, ${sizeVariantsLength} Sizes`
      : colorVariantsLength > 0
        ? `${colorVariantsLength} Colors`
        : sizeVariantsLength > 0
          ? `${sizeVariantsLength} Sizes`
          : 'None'

  return (
    <Box
      className=" m-5 p-5 rounded"
      sx={{ border: '1px solid', borderColor: 'accents.lightGrayAccent' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Label text="Category" color="secondary" />
          <Typography sx={{ fontWeight: '700' }}>
            {names?.toUpperCase() ?? 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Label text="Variants" color="secondary" />
          <Typography sx={{ fontWeight: '700' }}>{TotalVariant}</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Label text="Description" color="secondary" />
          <TextCollapse text={product.description ?? 'N/A'} />
        </Grid>
        <Grid item xs={6} md={6}>
          <Label text="Is Listed" color="secondary" />
          <Typography sx={{ fontWeight: '700' }}>
            {product.isListed ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Label text="Is Active" color="secondary" />
          <Typography sx={{ fontWeight: '700' }}>
            {product.isActive ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Label text="Created" color="secondary" />
          <Typography sx={{ fontWeight: '700' }}>
            {product.createdByUser.firstName}
            {', '}
            {dateTimeFormat(product.createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Label text="Last Updated" color="secondary" />
          <Typography sx={{ fontWeight: '700' }}>
            {dateTimeFormat(product.updatedAt)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BasicDetails
