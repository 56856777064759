import React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import { Paper } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import theme from 'styles/muiTheme'

const SearchFilter: React.FC = () => {
  return (
    <Paper
      className="mx-5"
      sx={{
        backgroundColor: 'secondary.main',
        boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
      }}
    >
      <TextField
        variant="standard"
        placeholder="Search Products"
        fullWidth
        sx={{
          backgroundColor: theme.palette.secondary.main,
          py: 1.5,
          px: 1,
          borderRadius: 1,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'black' }} />
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  )
}

export default SearchFilter
