/* eslint-disable react/no-unescaped-entities */
import { Box, Container, Divider, Typography } from '@mui/material'

import Footer from 'pages/home/components/Footer'
export const DataDeletionPolicy: React.FC = () => {
  return (
    <Box className="mt-20">
      {/* Header Section */}
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" gutterBottom>
            Data Deletion Policy
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Effective Date: 20 November 2024
          </Typography>
          <Divider />
          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              Manual Data Deletion Requests
            </Typography>
            <Typography paragraph>
              At Haulistic, your privacy is a priority. If you have connected
              your Instagram account to Haulistic and wish to delete your data,
              you can do so by following these steps:
            </Typography>
            <Typography paragraph>
              1. Send an email to{' '}
              <a href="mailto:support@haulistic.com">support@haulistic.com</a>{' '}
              with the subject line <strong>"Data Deletion Request"</strong>.
            </Typography>
            <Typography paragraph>
              2. Include the following details in your email:
              <ul>
                <li>
                  Your <strong>Haulistic account email address</strong>.
                </li>
                <li>
                  Your <strong>Instagram username</strong> (if applicable).
                </li>
                <li>A brief statement requesting data deletion.</li>
              </ul>
            </Typography>
            <Typography paragraph>
              3. Our support team will verify your identity and confirm your
              account ownership. Once verified, we will process your request and
              permanently delete your data within{' '}
              <strong>7 business days</strong>.
            </Typography>
          </Box>
          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              Important Notes
            </Typography>
            <Typography paragraph>
              - Data deletion requests are <strong>permanent</strong>. Once your
              data is deleted, it cannot be restored.
            </Typography>
            <Typography paragraph>
              - Certain data may be retained if required by law or for
              legitimate business purposes, as outlined in our Privacy Policy.
            </Typography>
          </Box>
          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Typography paragraph>
              If you have any questions or encounter issues with the data
              deletion process, please reach out to us at{' '}
              <a href="mailto:support@haulistic.com">support@haulistic.com</a>.
              We’re here to assist you.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  )
}
