import { FC, useEffect, useState } from 'react'

import { Button, Grid, InputAdornment, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import DateRangeInput from 'pages/dashboard/components/DateRangeInput'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'services/products/index.types'
import { shortDateFormat } from 'utils/dateFormat'

interface OfferRowType {
  id: number
  name: string
  price: number
  discountedPrice: string | number
  discountPercentage: string | number
  offerValidity: [Date | null, Date | null]
}

interface VariantsOfferDetailsProps {
  productId: number
  variants: {
    id: number
    name: string
    price: number
    discountedPrice: string | number
    discountPercentage: string | number
    offerValidity: [Date | null, Date | null]
  }[]
  onUpdate: (data?: Product) => void
}

const VariantsOfferDetails: FC<VariantsOfferDetailsProps> = ({
  productId,
  variants,
  onUpdate,
}) => {
  const [edit, setEdit] = useState(false)
  const [rows, setRows] = useState<OfferRowType[]>([])
  const [error, setError] = useState({
    idx: -1,
    fieldName: '',
    message: '',
  })
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setRows(variants)
  }, [variants])

  const handleFieldChange = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    if (fieldName === 'discountedPrice') {
      const data = rows.map((v, idx) => {
        if (idx === index) {
          if (v.price < +value)
            setError({
              idx: index,
              fieldName: fieldName + index,
              message: '*Less than price',
            })
          else
            setError({
              idx: -1,
              fieldName: '',
              message: '',
            })
          const percentage =
            value !== '' ? ((v.price - +value) / v.price) * 100 : 0
          return {
            ...v,
            [fieldName]: value,
            discountPercentage: percentage.toFixed(2),
          }
        }
        return v
      })
      setRows(data)
      return
    }

    const data = rows.map((v, idx) => {
      if (idx === index) {
        const disPrice = v.price - (+value * v.price) / 100
        return {
          ...v,
          [fieldName]: value,
          discountedPrice: disPrice,
        }
      }
      return v
    })
    setRows(data)
  }

  const handleDateChange = (
    value: [Date | null, Date | null],
    index: number
  ): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, offerValidity: value }
      }
      return v
    })
    setRows(data)
  }

  const handleFieldBlur = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.at(index)

    if (data && value !== '' && +value > data.price) {
      setError({
        idx: index,
        fieldName: fieldName + index,
        message: '*Less than price',
      })
    } else {
      setError({
        idx: -1,
        fieldName: '',
        message: '',
      })
    }
  }

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_VARIANT_OFFERS_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleSave = async (): Promise<void> => {
    if (organizationId)
      Amplitude.trackEvent('PRODUCT_VARIANT_OFFERS_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })

    const data = rows.map((obj) => ({
      ...obj,
      discountedPrice: +obj.discountedPrice,
      discountPercentage: +obj.discountPercentage,
      offerValidity:
        (obj.offerValidity as Date[]).length === 0
          ? undefined
          : obj.offerValidity,
    }))

    setIsSubmitting(true)
    const res = await updateProduct({
      productId,
      dataUpdate: { variants: data },
    })

    if (res.status === 'successful') {
      showSnackbar('Offers Updated Successfully', 'success')
      onUpdate(res.data)
    } else if (res.status === 'failed') {
      showSnackbar('Please try again later.', 'info')
    }
    setIsSubmitting(false)
    setEdit(false)
  }

  const handleCancel = (): void => {
    if (organizationId)
      Amplitude.trackEvent('PRODUCT_VARIANT_OFFERS_CANCEL_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    setRows(variants)
    setEdit(false)
  }

  return (
    <>
      <div className="mx-5">
        <SubTabHeader
          title="Offers & Discounts"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
      </div>
      <Card>
        {!edit &&
          variants.map((data, index) => (
            <Grid
              container
              columnSpacing={4}
              key={index}
              marginBottom={index < rows.length - 1 ? 2 : 0}
            >
              <Grid item xs={6} md={3}>
                <Label text="Variant Name" color="secondary" />

                <Typography
                  fontWeight={700}
                  sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                >
                  {data.name}
                </Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Label text="Discounted Price" color="secondary" />

                <Typography
                  fontWeight={700}
                  sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                >
                  {data.discountedPrice ? `₹${data.discountedPrice}` : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Label text="Discount Percentage" color="secondary" />

                <Typography
                  fontWeight={700}
                  sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                >
                  {data.discountPercentage
                    ? `${data.discountPercentage}%`
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
              >
                <Label text="Offer Validity" color="secondary" />

                <Typography
                  fontWeight={700}
                  sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                >
                  {data.offerValidity && data.offerValidity.length > 0
                    ? `${shortDateFormat(data.offerValidity[0]?.toString() ?? '')} - ${shortDateFormat(data.offerValidity[1]?.toString() ?? '')}`
                    : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        {edit && (
          <div>
            <Grid container>
              {rows.map((data, index) => (
                <Grid container key={index} mb={1} columnSpacing={2}>
                  <Grid item xs={6} md={2}>
                    <Label text="Variant" />
                    <Typography fontWeight={700}>{data.name}</Typography>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Label text="DiscountedPrice" />
                    <CustomTextField
                      name={'discountedPrice' + index}
                      type="number"
                      min={0}
                      value={data.discountedPrice.toString()}
                      onChange={(e) =>
                        handleFieldChange(
                          e.target.value,
                          index,
                          'discountedPrice'
                        )
                      }
                      onBlur={(e) =>
                        handleFieldBlur(
                          e.target.value,
                          index,
                          'discountedPrice'
                        )
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      error={Boolean(
                        error.fieldName === `discountedPrice${index}`
                      )}
                      helperText={
                        error.fieldName === `discountedPrice${index}` &&
                        error.message
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Label text="DiscountPercentage" />
                    <CustomTextField
                      name="discountPercentage"
                      type="number"
                      min={0}
                      value={data.discountPercentage.toString()}
                      onChange={(e) =>
                        handleFieldChange(
                          e.target.value,
                          index,
                          'discountPercentage'
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Label text="Offer Validity" />
                    <DateRangeInput
                      name="offerValidTill"
                      value={data.offerValidity}
                      onChange={(value) => handleDateChange(value, index)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <div className="mt-4">
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: 'none' }}
                onClick={handleSave}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
            </div>
          </div>
        )}
      </Card>
    </>
  )
}

export default VariantsOfferDetails
