import { useEffect, useState } from 'react'

import { useAuth } from 'hooks/useAuth'
import EmptyTabContent from 'pages/dashboard/components/EmptyTabContent'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { TabHeader } from 'pages/dashboard/components/TabHeader'
import { getAllProducts } from 'services/products'

import ProductList from '../components/List'

const ProductsPage = () => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const { organizationId, organization } = useAuth()

  useEffect(() => {
    async function get(): Promise<void> {
      const result = organizationId
        ? await getAllProducts({ orgId: organizationId })
        : false
      if (result && result.data.length > 0) setTableActive(true)
    }
    get()
  }, [organizationId])

  if (organization == null) {
    return <SetupStorePrompt />
  }

  return (
    <div>
      <div className="mb-5">
        {!tableActive && (
          <EmptyTabContent
            title="Products"
            description="This is where you can add new products, update existing listings, manage inventory, and adjust pricing. It's designed to help you effectively organize and optimize your product offerings for increased sales."
            addButtonText="Add Product"
            // importButtonText="Import Products"
          />
        )}

        {tableActive && organizationId && (
          <>
            <TabHeader title="Products" />
            <ProductList orgId={organizationId} />
          </>
        )}
      </div>
    </div>
  )
}

export default ProductsPage
