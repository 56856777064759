import React, { Dispatch, SetStateAction, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// import GoogleIcon from '@mui/icons-material/Google'
// import InstagramIcon from '@mui/icons-material/Instagram'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import * as z from 'zod'

import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import theme from 'styles/muiTheme'

import { register } from '../../services/auth'

const FormSchema = z
  .object({
    firstName: z.string().min(1, 'First name is required').max(100),
    lastName: z.string().min(1, 'Last name is required').max(100),
    email: z.string().min(1, 'Email is required').email('Invalid email'),
    password: z
      .string()
      .min(8, 'Password must be 8+ characters')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$*_^&])[A-Za-z\d@#$*_^&]{8,}$/,
        'Password must be 8+ characters with at least one uppercase, one lowercase, one digit, and one special character (@, #, $, *, ^, &)'
      ),
    confirmPassword: z.string().min(1, 'Password confirmation is required'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  })

interface FormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

interface SignUpComponentProps {
  setSignupData: Dispatch<SetStateAction<{ email: string; password: string }>>
  onActiveForm: (form: 'signup' | 'confirmRegistration') => void
  onSignInClick: () => void
}

const SignUpForm: React.FC<SignUpComponentProps> = ({
  setSignupData,
  onActiveForm,
  onSignInClick,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { showSnackbar } = useSnackbar()

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = (): void => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const result = await register(values)
    if (values) {
      Amplitude.trackEvent('CREATE_ACCOUNT_REGISTER_CLICKED', {
        userEmail: values.email,
      })
    }

    if (result.status === 'successful') {
      showSnackbar('Please verify your email.', 'info')
      setSignupData({ email: values.email, password: values.password })
      onActiveForm('confirmRegistration')
    } else if (result.status === 'failed') {
      result?.error
        ? showSnackbar(result.error, 'error')
        : showSnackbar('Please try again later.', 'error')
    }
  }

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-24">
          <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
            <h2 className="text-center text-2xl font-bold leading-tight text-black">
              Sign up to create an account
            </h2>
            <p className="mt-2 text-center text-base text-gray-600">
              Already have an account?
              <Link onClick={onSignInClick}>Sign In</Link>
            </p>
            <div className="mt-8 space-y-5">
              {/* Full Name */}
              <div>
                <label
                  htmlFor="firstName"
                  className="text-base font-medium text-gray-900"
                >
                  First Name
                </label>
                <div className="mt-1">
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={field.onChange}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="text"
                        placeholder="First Name"
                        id="firstName"
                        autoComplete="firstName"
                      />
                    )}
                  />
                  {errors.firstName && (
                    <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                      *{errors.firstName?.message}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="text-base font-medium text-gray-900"
                >
                  Last Name
                </label>
                <div className="mt-1">
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={field.onChange}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="text"
                        placeholder="Last Name"
                        id="lastName"
                        autoComplete="lastName"
                      />
                    )}
                  />
                  {errors.lastName && (
                    <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                      *{errors.lastName?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="text-base font-medium text-gray-900"
                >
                  Email
                </label>
                <div className="mt-1">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={field.onChange}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                        id="email"
                        autoComplete="email"
                      />
                    )}
                  />
                </div>
                {errors.email && (
                  <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                    *{errors.email?.message}
                  </p>
                )}
              </div>
              {/* Password */}
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="text-base font-medium text-gray-900"
                  >
                    Password
                  </label>
                  <IconButton
                    sx={{ color: theme.palette.text.primary, padding: 0 }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </div>
                <div className=" mt-1">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={field.onChange}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        id="password"
                        autoComplete="new-password"
                      />
                    )}
                  />
                  {errors.password && (
                    <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                      *{errors.password?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Confirm Password */}
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="confirmPassword"
                    className="text-base font-medium text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <IconButton
                    type="button"
                    sx={{ color: theme.palette.text.primary, padding: 0 }}
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </div>
                <div className=" mt-1">
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={field.onChange}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        autoComplete="new-password"
                      />
                    )}
                  />
                  {errors.confirmPassword && (
                    <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                      *{errors.confirmPassword?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Sign up with Google */}
              {/* <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex w-full items-center justify-center rounded-md border border-red-500 px-3.5 py-2.5 font-semibold leading-7 text-black transition-all duration-300 hover:bg-red-100 hover:border-red-700 hover:text-red-700"
                >
                  <GoogleIcon className="mr-2" />
                  Sign up with Google
                </button>
              </div> */}

              {/* Sign up with Instagram */}
              {/* <div className="mt-2">
                <button
                  type="button"
                  className="inline-flex w-full items-center justify-center rounded-md border border-blue-500 px-3.5 py-2.5 font-semibold leading-7 text-black transition-all duration-300 hover:bg-pink-50 hover:border-pink-500 hover:text-pink-500"
                >
                  <InstagramIcon className="mr-2" />
                  Sign up with Instagram
                </button>
              </div> */}
              {/* Submit Button */}
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{ width: '100%' }}
                >
                  Create Account <ArrowForwardIcon className="ml-2" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default SignUpForm
