import { getToken } from 'utils/authCookies'

import { InviteData, StoreUsers } from './index.type'

const organizationBasePath = `${process.env.REACT_APP_API_BASE_PATH}/organization`

const getStoreUsers = async (
  id: number
): Promise<{
  status: string
  data?: StoreUsers
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    const { data, message } = await res.json()

    if (res.ok) {
      return { status: 'successful', data: data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const inviteAsMember = async (inviteData: {
  emails: string[]
  role: string
  organizationId: number
}): Promise<{
  status: string
  data?: InviteData[]
  message?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(`${organizationBasePath}/invite`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(inviteData),
    })

    const { data, message } = await res.json()

    if (res.ok) {
      return { status: 'successful', data: data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

const changeRoleInOrganization = async (roleChangeData: {
  userId: number
  role: string
  organizationId: number
}): Promise<{
  status: string
  message?: string
  updatedUserRole?: string
}> => {
  const token = await getToken()

  try {
    const res = await fetch(
      `${organizationBasePath}/${roleChangeData.organizationId}/role`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: JSON.stringify({
          userId: roleChangeData.userId,
          role: roleChangeData.role,
        }),
      }
    )

    const { message, updatedUserRole } = await res.json()

    if (res.ok) {
      return { status: 'successful', updatedUserRole }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
    return { status: 'failed', message: 'Please try again later.' }
  }
}

export { getStoreUsers, inviteAsMember, changeRoleInOrganization }
