import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { FieldArray, useFormikContext } from 'formik'

import VariantRow from './VariantRow'
import { ProductDetailFormValues } from '../pages/AddProductPage'

interface ProductVariantsProps {
  productId?: number
  onVariantAdd: (val: boolean) => void
}

const ProductVariants: React.FC<ProductVariantsProps> = ({ onVariantAdd }) => {
  const formik = useFormikContext<ProductDetailFormValues>()

  return (
    <FieldArray name="variants">
      {({ remove, push }) => (
        <div>
          {formik.values.variants.map((variant, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                marginBottom: 1.5,
              }}
            >
              <VariantRow
                type={variant.type}
                index={index}
                onRemove={remove}
                formik={formik}
                onVariantAdd={onVariantAdd}
              />
            </Box>
          ))}
          <Button
            type="button"
            size="small"
            sx={{
              display: 'block',
            }}
            variant="outlined"
            onClick={() => {
              push({
                name: '',
                type: '',
                price: '',
                quantity: '',
                costPerUnit: '',
                discountedPrice: '',
                discountPercentage: '',
                offerValidTill: [null, null],
              })
            }}
          >
            + Add more Variants
          </Button>
        </div>
      )}
    </FieldArray>
  )
}

export default ProductVariants
