import { ChangeEvent, FC } from 'react'

import { Box, Grid, InputAdornment } from '@mui/material'
import { useFormikContext } from 'formik'

import Card from 'components/card'
import Label from 'components/forms/Label'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import DateRangeInput from 'pages/dashboard/components/DateRangeInput'

import OfferTable from './OfferTable'
import { ProductDetailFormValues } from '../pages/AddProductPage'

interface ProductOffersProps {
  variantAdded: boolean
}

const ProductOffers: FC<ProductOffersProps> = ({ variantAdded }) => {
  const formik = useFormikContext<ProductDetailFormValues>()

  const handleDateChange = (update: [Date | null, Date | null]): void => {
    formik.setFieldValue('offerValidTill', update)
  }
  const handleDiscountPriceChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    formik.setFieldValue('discountedPrice', e.target.value)
    if (formik.values.price !== '') {
      const percentage =
        ((+formik.values.price - +e.target.value) / +formik.values.price) * 100
      formik.setFieldValue('discountPercentage', percentage.toFixed(2))
    }
  }

  const handleDiscountPercentageChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    formik.setFieldValue('discountPercentage', e.target.value)
    if (formik.values.price !== '') {
      const disPrice =
        +formik.values.price - (+e.target.value * +formik.values.price) / 100
      formik.setFieldValue('discountedPrice', disPrice.toFixed(0))
    }
  }

  return (
    <div className=" mb-5">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mr-4"
      >
        <h2 className="text-lg font-semibold ml-4">Offers & Discounts</h2>
      </Box>
      <Card>
        {!variantAdded ? (
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Discounted Price" htmlFor="discountedPrice" />
              <CustomTextField
                type="number"
                name="discountedPrice"
                min={0}
                value={formik.values.discountedPrice}
                onChange={handleDiscountPriceChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.discountedPrice) &&
                  formik.touched.discountedPrice
                }
                helperText={
                  formik.touched.discountedPrice &&
                  formik.errors.discountedPrice
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Discount Percentage" htmlFor="discountPercentage" />
              <CustomTextField
                type="number"
                name="discountPercentage"
                min={0}
                value={formik.values.discountPercentage}
                onChange={handleDiscountPercentageChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.discountPercentage) &&
                  formik.touched.discountPercentage
                }
                helperText={
                  formik.touched.discountPercentage &&
                  formik.errors.discountPercentage
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Offer validity" htmlFor="offerValidTill" />
              <DateRangeInput
                name="offerValidTill"
                value={formik.values.offerValidTill}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
        ) : (
          <OfferTable />
        )}
      </Card>
    </div>
  )
}
export default ProductOffers
