import React from 'react'

import styles from './index.module.css'
import placeholderImage from '../../assests/img/sunrise-placeholder.png'

interface ImageComponentProps {
  imageUrl?: string
  altText?: string
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  imageUrl,
  altText,
}) => {
  return (
    <div className={styles.imageContainer}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={altText || 'Image'}
          className={styles.image}
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      ) : (
        <img
          src={placeholderImage}
          alt="Placeholder"
          width={100}
          height={100}
          className={styles.image}
        />
      )}
    </div>
  )
}

export default ImageComponent
