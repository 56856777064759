import { FormControlLabel, Switch, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik'

import Card from 'components/card'

import { ProductDetailFormValues } from '../pages/AddProductPage'

const ProductStatus = (): JSX.Element => {
  const formik = useFormikContext<ProductDetailFormValues>()
  return (
    <div className=" mb-5">
      <h2 className="text-lg font-semibold ml-4">Manage Status</h2>
      <Card>
        <div className="mb-4">
          <FormControlLabel
            control={
              <Field
                as={Switch}
                name="isListed"
                checked={formik.values.isListed}
                onChange={formik.handleChange}
              />
            }
            label="List Product in Marketplace"
          />
          <Typography variant="body2" color="textSecondary">
            Turn this on to make the product visible and searchable in the
            marketplace upon creation.
          </Typography>
        </div>
        <div>
          <FormControlLabel
            control={
              <Field
                as={Switch}
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                color="primary"
              />
            }
            label="Activate Product for Sale"
          />
          <Typography variant="body2" color="textSecondary">
            Enable this to allow customers to purchase the product once it’s
            listed.
          </Typography>
        </div>
      </Card>
    </div>
  )
}
export default ProductStatus
