import { Box, Container, Divider, Typography } from '@mui/material'

import Footer from 'pages/home/components/Footer'

export const TermsOfService: React.FC = () => {
  return (
    <Box className="mt-20">
      {/* Header Section */}
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" gutterBottom>
            Haulistic Terms of Service
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Effective Date: 6 August 2024
          </Typography>
          <Divider />
          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              1. Introduction
            </Typography>
            <Typography paragraph>
              Welcome to Haulistic, operated by CARTCREST E-MARKET SOLUTIONS
              PRIVATE LIMITED {`("we," "our," or "us").`} By using our platform,
              you agree to these Terms of Service {`("Terms").`} If you do not
              agree to these Terms, you may not use our services.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              2. Definitions
            </Typography>
            <Typography paragraph>
              <strong>&quot;Platform&quot;</strong> refers to the Haulistic
              website, dashboard application, and any associated services
              provided by CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED.
              <br />
              <strong>&quot;User&quot;</strong> refers to anyone who accesses or
              uses the Platform.
              <br />
              <strong>&quot;Seller&quot;</strong> refers to Users who register
              to sell products through their Instagram account using the
              Platform.
              <br />
              <strong>&quot;Customer&quot;</strong> refers to Users who purchase
              products listed by Sellers on the Platform.
              <br />
              <strong>&quot;Services&quot;</strong> refers to the tools and
              features provided by Haulistic, including product listing,
              inventory management, payment integration, delivery integration,
              and analytics.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              3. Account Registration
            </Typography>
            <Typography paragraph>
              Users must register for an account to access certain features of
              the Platform.
              <br />
              Sellers must provide accurate and up-to-date information about
              their business, products, and Instagram account.
              <br />
              Users are responsible for maintaining the confidentiality of their
              account credentials and are responsible for all activities that
              occur under their account.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              4. Use of the Platform
            </Typography>
            <Typography paragraph>
              Users agree to use the Platform in compliance with all applicable
              laws and regulations.
              <br />
              Sellers are responsible for listing accurate product descriptions,
              pricing, inventory levels, and ensuring the availability of
              products.
              <br />
              Sellers must ensure that their Instagram account complies with
              Instagram’s terms of service and community guidelines.
              <br />
              Sellers may use the Platform to customize their customer-facing
              application, including branding, pricing, and discount offers.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              5. Payments and Transactions
            </Typography>
            <Typography paragraph>
              Payment processing for transactions on the Platform is facilitated
              by third-party payment processors.
              <br />
              Sellers are responsible for ensuring that payments are correctly
              processed and that funds are received.
              <br />
              CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED is not responsible
              for any payment disputes between Sellers and Customers.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              6. Delivery and Fulfillment
            </Typography>
            <Typography paragraph>
              Sellers are responsible for integrating with delivery services and
              ensuring that products are shipped to Customers promptly and
              accurately.
              <br />
              Haulistic provides tools for delivery integration but is not
              responsible for the actual delivery of products.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              7. Intellectual Property
            </Typography>
            <Typography paragraph>
              CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED owns all rights to
              the Platform, including but not limited to software, design,
              content, and trademarks.
              <br />
              Users may not use any content or trademarks from the Platform
              without explicit permission.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              8. Limitation of Liability
            </Typography>
            <Typography paragraph>
              CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED is not liable for any
              damages or losses arising from the use of the Platform, including
              but not limited to loss of profits, data, or business
              opportunities.
              <br />
              The Platform is provided &quot;as is&quot; without any warranties,
              express or implied.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              9. Termination
            </Typography>
            <Typography paragraph>
              CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED reserves the right to
              terminate or suspend access to the Platform for any reason,
              including violation of these Terms.
              <br />
              Sellers may terminate their account at any time but are
              responsible for fulfilling any outstanding orders.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              10. Changes to Terms
            </Typography>
            <Typography paragraph>
              CARTCREST E-MARKET SOLUTIONS PRIVATE LIMITED may modify these
              Terms at any time. Users will be notified of any changes, and
              continued use of the Platform constitutes acceptance of the new
              Terms.
            </Typography>
          </Box>

          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              11. Governing Law
            </Typography>
            <Typography paragraph>
              These Terms are governed by the laws of the Republic of India, and
              the courts of Bangalore, Karnataka, shall have exclusive
              jurisdiction.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  )
}
