import React from 'react'

import { Box, TextField } from '@mui/material'
import { useFormikContext } from 'formik'

import Card from 'components/card'
import Label from 'components/forms/Label'
import QuillEditor from 'components/quill'
import CategoryInput from 'pages/dashboard/components/CategoryInput'

import { ProductDetailFormValues } from '../pages/AddProductPage'

interface ProductDetailsProps {
  productId?: number
}

const ProductDetails: React.FC<ProductDetailsProps> = () => {
  const formik = useFormikContext<ProductDetailFormValues>()

  const handleDescriptionChange = (content: string): void => {
    formik.setFieldValue('description', content)
  }

  const handleCategoryChange = (ids: number[]): void => {
    formik.setFieldValue('category', ids)
  }

  return (
    <div className="mb-5">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mr-4"
      >
        <h2 className="text-lg font-semibold ml-4">Details</h2>
      </Box>

      <Card>
        <Box>
          <Label text="Name" htmlFor="name" />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            inputProps={{ style: { backgroundColor: 'white' } }}
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>
        <Box className="mt-4">
          <Label text="Category" />
          <CategoryInput onChange={handleCategoryChange} />
        </Box>
        <Box className="mt-4">
          <Label text="Description" htmlFor="description" />
          <Box className="flex">
            <Box className="flex-1">
              <QuillEditor
                value={formik.values.description} // Pass the current description
                onChange={handleDescriptionChange} // Update description on change
                placeholder="Write your product description here..."
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </div>
  )
}

export default ProductDetails
