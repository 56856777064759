import { useAuth } from 'hooks/useAuth'

import PhonePeSetup from './components/phonepe/PhonePeSetup'

const PaymentSettings = () => {
  const { organization } = useAuth()

  return (
    <div>
      {organization?.defaultPaymentMethod === 'PHONEPE' && (
        <PhonePeSetup paymentSetupStatus={organization?.paymentSetupStatus} />
      )}
    </div>
  )
}

export default PaymentSettings
