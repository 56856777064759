import { FC, ChangeEvent, useState, useEffect } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Typography,
  IconButton,
  Link,
  Button,
  Dialog,
} from '@mui/material'
import { useParams } from 'react-router-dom'

import Card from 'components/card'
import SingleImageCropper from 'components/files/SingleFileCropDialog'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import EditButton from 'pages/dashboard/components/EditButton'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { getPresignedUrl, uploadFile, deleteFile } from 'services/files'
import { updateExistingCategory } from 'services/products/categories'
import { validateCategoryImage } from 'utils/validation'

interface DetailsSectionProps {
  description: string
  imageUrl: string | null
  setImageUrl: (url: string) => void
  setDescription: (desc: string) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const CategoryDetailsSection: FC<DetailsSectionProps> = ({
  description,
  imageUrl,
  setImageUrl,
  setDescription,
}) => {
  const [image, setImage] = useState<string | null>(imageUrl)
  const [error, setError] = useState<{ type: string; message: string } | null>(
    null
  )
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number
    fileName: string
  }>({
    progress: 0,
    fileName: '',
  })
  const [isUploading, setIsUploading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { categoryId } = useParams<{ categoryId: string }>()
  const { organizationId } = useAuth()
  const [cropFile, setCropFile] = useState<File | null>(null)
  const [showCropper, setShowCropper] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    setImage(imageUrl)
  }, [imageUrl])

  const handleSave = async (file: File): Promise<void> => {
    if (!categoryId) {
      setError({
        type: 'image',
        message: 'Category ID is missing. Cannot update category.',
      })
      return
    }

    setIsUploading(true)

    try {
      const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
      setUploadProgress({ progress: 0, fileName: file.name })

      const result = await uploadFile(file, signedUrl, (progress) => {
        setUploadProgress({ progress, fileName: file.name })
      })

      if (result.status === 'successful') {
        const imageUrl = `${fileUrl}/${uuid}`
        setImage(imageUrl)
        setImageUrl(imageUrl)
        setError(null)

        const updateData = { imageUrl }
        const updateResult = await updateExistingCategory(
          categoryId,
          updateData
        )

        if (updateResult.status !== 'successful') {
          setError({
            type: 'update',
            message: updateResult.message || 'Failed to update category.',
          })
        }
      } else {
        setError({
          type: 'upload',
          message: 'File upload failed.',
        })
      }
    } catch (error) {
      setError({
        type: 'upload',
        message: 'An error occurred while uploading the file.',
      })
    } finally {
      setIsUploading(false)
    }
  }

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    const file = e.target.files[0]
    const validate = await validateCategoryImage(file)

    if (!validate.valid)
      return setError({
        type: 'image',
        message: validate.error || 'An error occurred.',
      })

    setCropFile(file)
    setShowCropper(true)
  }

  const handleCropComplete = (croppedFile: File): void => {
    handleSave(croppedFile)
    setCropFile(null)
    setShowCropper(false)
  }

  const handleCropCancel = (): void => {
    setShowCropper(false)
    setCropFile(null)
  }

  const handleDeleteImage = async (): Promise<void> => {
    if (!image) return

    if (!categoryId) {
      setError({
        type: 'image',
        message: 'Category ID is missing. Cannot delete image.',
      })
      return
    }

    const fileName = image.split('/')[3]

    try {
      const result = await deleteFile(fileName)

      if (result.status === 'successful') {
        setImage(null)
        setImageUrl('')

        const updateData = { imageUrl: '' }
        const updateResult = await updateExistingCategory(
          categoryId,
          updateData
        )

        if (updateResult.status !== 'successful') {
          setError({
            type: 'update',
            message:
              updateResult.message ||
              'Failed to update category after image deletion.',
          })
        }
      } else {
        setError({
          type: 'delete',
          message: 'Image deletion failed.',
        })
      }
    } catch (error) {
      setError({
        type: 'delete',
        message: 'An error occurred while deleting the image.',
      })
    }
  }

  const handleEditDescription = (): void => {
    setIsEditing(true)
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_DETAILS_EDIT_CLICKED', {
        orgId: organizationId,
      })
    }
  }

  const handleSaveDescription = async (): Promise<void> => {
    if (!categoryId) {
      console.error('Category ID is missing. Cannot update category.')
      return
    }
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_DETAILS_SAVE_CLICKED', {
        orgId: organizationId,
      })
    }
    const updateData = {
      description: description,
    }

    setIsSubmitting(true)
    const result = await updateExistingCategory(categoryId, updateData)

    if (result.status === 'successful') {
      setDescription(description)
      showSnackbar('Description updated successfully', 'success')
    } else {
      showSnackbar(result.message ?? 'Please try again later', 'error')
    }
    setIsSubmitting(false)
    setIsEditing(false)
  }

  const handleCancel = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_DETAILS_CANCEL_CLICKED', {
        orgId: organizationId,
      })
    }
    setIsEditing(false)
  }

  return (
    <>
      <Box
        className="mx-5 mb-4"
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography sx={{ fontWeight: '700' }}>Details</Typography>
        {!isEditing && (
          <EditButton buttonText={'Edit'} onClick={handleEditDescription} />
        )}
      </Box>
      <Card>
        <Box className="mb-4">
          <Label text="Description" color="secondary" />
          {isEditing ? (
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full border border-gray-300 rounded py-2 px-3 focus:border-black focus:outline-none"
            />
          ) : (
            <Typography variant="body1">{description || 'N/A'}</Typography>
          )}
        </Box>
        <Label text="Image" color="secondary" />
        {image ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link href={image} underline="hover" target="_blank" rel="noopener">
              {image.split('/')[3]}
            </Link>
            {isEditing && (
              <IconButton
                onClick={handleDeleteImage}
                aria-label="delete"
                size="small"
              >
                <DeleteIcon
                  fontSize="inherit"
                  className="text-gray-600 hover:text-red-600"
                />
              </IconButton>
            )}
          </Box>
        ) : (
          <InputFileUpload
            name="image"
            onChange={handleFileChange}
            buttonText={isUploading ? 'Uploading' : 'Upload File'}
          />
        )}
        {uploadProgress.progress > 0 && uploadProgress.progress < 100 && (
          <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
            {uploadProgress.fileName} - {uploadProgress.progress}%
          </Typography>
        )}
        {error && error.type === 'image' && (
          <p className="text-xs text-red-500 block mt-1">{error.message}</p>
        )}
        {isEditing && (
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSaveDescription}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </div>
        )}
      </Card>
      {showCropper && cropFile && (
        <Dialog open={showCropper} maxWidth="md" fullWidth>
          <SingleImageCropper
            file={cropFile}
            onCropComplete={handleCropComplete}
            onClose={handleCropCancel}
          />
        </Dialog>
      )}
    </>
  )
}

export default CategoryDetailsSection
