import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios'

const fileBasePath = `${process.env.REACT_APP_API_BASE_PATH}/files`

export const getPresignedUrl = async (): Promise<{
  url: string
  fileName: string
}> => {
  try {
    const res = await fetch(`${fileBasePath}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })

    if (!res.ok) {
      throw new Error('Failed to get presigned URL')
    }

    const { data } = await res.json()
    return data
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}

export const deleteFile = async (
  fileName: string
): Promise<{ status: string }> => {
  try {
    const res = await fetch(`${fileBasePath}`, {
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName }),
    })

    if (res.ok) {
      return { status: 'successful' }
    }

    return { status: 'failed' }
  } catch (error) {
    console.error('Error: ', error)
    throw error // Rethrow the error to be handled by the caller
  }
}
export const uploadFile = async (
  file: File,
  url: string,
  onProgress: (percentage: number) => void
): Promise<{ status: string }> => {
  try {
    // Check if file size exceeds the limit : 5MB
    const maxSizeBytes = 5 * 1024 * 1024
    if (file.size > maxSizeBytes) {
      throw new Error('File size exceeds the maximum allowed size of 5MB.')
    }

    const config: AxiosRequestConfig = {
      method: 'put',
      url: url,
      headers: {
        'Content-Type': file.type,
      },
      data: file,
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )
          onProgress(percentage)
        }
      },
    }

    const res = await axios(config)

    if (res.status === 200) {
      return { status: 'successful' }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error uploading file:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}
