import { getAuthCookie } from 'utils/authCookies'

const accountBasePath = `${process.env.REACT_APP_API_BASE_PATH}/user`

const getPendingInvitations = async () => {
  const token = getAuthCookie('IdToken')
  try {
    const res = await fetch(`${accountBasePath}/invites`, {
      method: 'GET',
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    })

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error
  }
}

export { getPendingInvitations }
