import EmailIcon from '@mui/icons-material/Email'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LocationOn from '@mui/icons-material/LocationOn'
import XIcon from '@mui/icons-material/X'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { contactUsFormAccess } from 'services/auth'

const ContactForm = (): JSX.Element => {
  const { showSnackbar } = useSnackbar()

  const icons: Record<'Email' | 'LinkedIn' | 'X' | 'Instagram', JSX.Element> = {
    Email: <EmailIcon color="primary" />,
    LinkedIn: <LinkedInIcon color="primary" />,
    X: <XIcon color="primary" />,
    Instagram: <InstagramIcon color="primary" />,
  }

  const links: Record<'Email' | 'LinkedIn' | 'X' | 'Instagram', string> = {
    Email: 'mailto:support@haulistic.io',
    LinkedIn: 'https://www.linkedin.com/company/haulistic-io/',
    X: 'https://x.com/hiretheauthor',
    Instagram: 'https://www.instagram.com/haulistic.io',
  }

  const labels: Array<'Email' | 'LinkedIn' | 'X' | 'Instagram'> = [
    'Email',
    'LinkedIn',
    'X',
    'Instagram',
  ]

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
      .required('Phone Number is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string()
      .min(10, 'Message must be at least 10 characters')
      .required('Message is required'),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      subject: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        console.warn('Submitted Values:', values)

        const { name, email, phoneNumber, subject, message } = values

        const response = await contactUsFormAccess({
          name,
          email,
          phonenumber: phoneNumber,
          subject,
          message,
        })

        if (response?.status === 'success') {
          showSnackbar(
            'Your request has been received. Our team will get back to you in less than 24 hours.',
            'success'
          )
          resetForm()
        } else {
          showSnackbar(
            'Failed to submit your request. Please try again.',
            'error'
          )
        }
      } catch (error) {
        console.error('Error submitting form:', error)
        showSnackbar('An error occurred. Please try again.', 'error')
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <Box className="max-w-3xl mx-auto mt-20 px-4">
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold' }}
            className=" text-gray-800"
          >
            Contact Us
          </Typography>
        </Box>
        <Card>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 1 }}>
              <Label text="Name" htmlFor="name" color="secondary" />
              <CustomTextField
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Label text="Email" htmlFor="email" color="secondary" />
              <CustomTextField
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Label
                text="Phone Number"
                htmlFor="phoneNumber"
                color="secondary"
              />
              <CustomTextField
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </Box>

            <Box sx={{ mb: 1 }}>
              <Label text="Subject" htmlFor="subject" color="secondary" />
              <CustomTextField
                id="subject"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Box>

            <Box sx={{ mb: 1 }}>
              <Label text="Message" htmlFor="message" color="secondary" />
              <textarea
                id="message"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '4px',
                  border:
                    formik.touched.message && formik.errors.message
                      ? '1px solid red'
                      : '1px solid #ccc',
                  resize: 'vertical',
                }}
                className="py-2 px-3 focus:border-black focus:outline-none"
              />
              {formik.touched.message && formik.errors.message && (
                <p className="text-xs text-red-500 block mt-1">
                  {formik.errors.message}
                </p>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 1.5,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                size="small"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? 'Submitting' : 'Submit'}
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
      <Box className="max-w-5xl mx-auto my-10 px-4">
        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold' }}
            className=" text-gray-800"
          >
            Alternatively, reach out to us via
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {labels.map((label) => (
                  <Grid item xs={6} key={label}>
                    <Box
                      className="p-2 rounded underline text-center flex items-center justify-center hover:cursor-pointer"
                      sx={{
                        border: '1px solid',
                        borderColor: 'accents.lightGrayAccent',
                        textAlign: 'center',
                        gap: 1,
                      }}
                      onClick={() => window.open(links[label], '_blank')}
                    >
                      {icons[label]}
                      <Label text={label} color="secondary" />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                className="p-2 rounded"
                sx={{
                  border: '1px solid',
                  borderColor: 'accents.lightGrayAccent',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                  gap: 1,
                }}
              >
                <Box className="flex">
                  <LocationOn color="primary" fontSize="large" />
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Registered Address
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 200,
                      textDecoration: 'underline',
                    }}
                  >
                    224, 3RD FLOOR, #80/3, VIJINAPUR VILLAGE, Doorvaninagar,
                    Bangalore North, Bangalore - 560016, Karnataka
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default ContactForm
