import { useEffect, useState } from 'react'

import { CheckCircle } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { createPhonePeAccount } from 'services/payments'

interface PhonePeSetupProps {
  paymentSetupStatus: string
}

const PhonePeSetup = ({
  paymentSetupStatus,
}: PhonePeSetupProps): JSX.Element => {
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSuccess, setIsSuccess] = useState(false)

  const validationSchema = Yup.object({
    merchantId: Yup.string().required('Merchant ID is required'),
    saltIndex: Yup.number()
      .required('Salt Index is required')
      .typeError('Salt Index must be a number'),
    saltKey: Yup.string().required('Salt Key is required'),
  })

  useEffect(() => {
    if (paymentSetupStatus === 'COMPLETED') {
      setIsSuccess(true)
    }
  }, [paymentSetupStatus])

  const formik = useFormik({
    initialValues: {
      merchantId: '',
      saltIndex: '',
      saltKey: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const parsedValues = {
          ...values,
          saltIndex: Number(values.saltIndex),
        }

        if (organizationId === null) {
          console.error('Organization ID is null')
          showSnackbar(
            'Failed to set up PhonePe account: Invalid organization ID',
            'error'
          )
          return
        }

        const result = await createPhonePeAccount(organizationId, parsedValues)

        if (result.status === 'successful') {
          setIsSuccess(true)

          resetForm()
          showSnackbar('PhonePe account connected successfully', 'success')
        } else {
          showSnackbar(
            `Failed to set up PhonePe account: ${result.message}`,
            'error'
          )
        }
      } catch (error) {
        console.error('Error setting up PhonePe account:', error)
        showSnackbar('An unexpected error . Please try again.', 'error')
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      {isSuccess ? (
        <Card>
          <Box>
            <Box display="flex" alignItems="center" gap={1}>
              <CheckCircle sx={{ color: 'green', fontSize: 24 }} />
              <Typography>PhonePe account connected successfully</Typography>
            </Box>
          </Box>
        </Card>
      ) : (
        <>
          <Box sx={{ margin: 2 }}>
            <Typography variant="h6">Connect your PhonePe Account</Typography>
          </Box>
          <Card>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 1 }}>
                <Label
                  text="Merchant ID"
                  htmlFor="merchantId"
                  color="secondary"
                />
                <CustomTextField
                  id="merchantId"
                  name="merchantId"
                  value={formik.values.merchantId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.merchantId &&
                    Boolean(formik.errors.merchantId)
                  }
                  helperText={
                    formik.touched.merchantId && formik.errors.merchantId
                  }
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Label
                  text="Salt Index"
                  htmlFor="saltIndex"
                  color="secondary"
                />
                <CustomTextField
                  id="saltIndex"
                  name="saltIndex"
                  value={formik.values.saltIndex}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  error={
                    formik.touched.saltIndex && Boolean(formik.errors.saltIndex)
                  }
                  helperText={
                    formik.touched.saltIndex && formik.errors.saltIndex
                  }
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Label text="Salt Key" htmlFor="saltKey" color="secondary" />
                <CustomTextField
                  id="saltKey"
                  name="saltKey"
                  value={formik.values.saltKey}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.saltKey && Boolean(formik.errors.saltKey)
                  }
                  helperText={formik.touched.saltKey && formik.errors.saltKey}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 1.5,
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Submitting' : 'Submit'}
                </Button>
              </Box>
            </form>
          </Card>
        </>
      )}
    </>
  )
}

export default PhonePeSetup
