import { ChangeEvent, FC, useState } from 'react'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material'
import { FormikProps } from 'formik'

import Label from 'components/forms/Label'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import DateRangeInput from 'pages/dashboard/components/DateRangeInput'

import { ProductDetailFormValues } from '../pages/AddProductPage'

type OffersArray = {
  variantName: string
  discountedPrice: string | number
  discountPercentage: string | number
  offerValidTill: [Date | null, Date | null]
  offerAddded: boolean
}

interface OfferRowProps {
  list: string[]
  index: number
  onRemove: (index: number) => void
  offersArray: OffersArray[]
  onChange: (offers: OffersArray[]) => void
  formik: FormikProps<ProductDetailFormValues>
}

const OfferRow: FC<OfferRowProps> = ({
  index,
  offersArray,
  onRemove,
  list,
  onChange,
  formik,
}) => {
  const [error, setError] = useState({
    name: false,
    discountedPrice: false,
    message: '',
  })

  const isPreviewOn = formik.values.name !== ''

  const handleVariantNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const updatedItems = offersArray.map((item, i) =>
      i === index ? { ...item, variantName: e.target.value } : item
    )
    onChange(updatedItems)
  }

  const handleDiscountedPriceChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    let percentage: number | undefined
    const variantName = offersArray[index].variantName

    if (variantName !== '') {
      const variantIndex = formik.values.variants.findIndex(
        (obj) => obj.name === variantName
      )
      const variantPrice = formik.values.variants[variantIndex].price

      if (variantPrice !== '') {
        const calculatePercentage =
          ((+variantPrice - +e.target.value) / +variantPrice) * 100
        percentage = +calculatePercentage.toFixed(2)
      }
    }

    const updatedItems = offersArray.map((item, i) =>
      i === index
        ? {
            ...item,
            discountedPrice: e.target.value,
            discountPercentage: percentage ? percentage : '',
          }
        : item
    )

    onChange(updatedItems)
  }

  const handlePercentageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let discountedPrice: number | undefined

    const variantName = offersArray[index].variantName
    if (variantName !== '') {
      const variantIndex = formik.values.variants.findIndex(
        (obj) => obj.name === offersArray[index].variantName
      )
      const variantPrice = formik.values.variants[variantIndex].price

      if (variantPrice !== '') {
        const calculatePrice =
          +variantPrice - (+e.target.value * +variantPrice) / 100
        discountedPrice = +calculatePrice.toFixed(0)
      }
    }

    const updatedItems = offersArray.map((item, i) =>
      i === index
        ? {
            ...item,
            discountedPrice: discountedPrice ? discountedPrice : '',
            discountPercentage: e.target.value,
          }
        : item
    )

    onChange(updatedItems)
  }

  const handleDateChange = (
    index: number,
    value: [Date | null, Date | null],
    fieldName: string
  ): void => {
    const updatedItems = offersArray.map((item, i) =>
      i === index ? { ...item, [fieldName]: value } : item
    )

    onChange(updatedItems)
  }

  const handleOnSave = (index: number): void => {
    setError({ name: false, discountedPrice: false, message: '' })

    const variantName = offersArray[index].variantName

    if (variantName === '') {
      setError((prev) => ({ ...prev, name: true, message: '*Select name' }))
      return
    }

    if (
      offersArray[index].discountedPrice === '' &&
      offersArray[index].discountPercentage === '' &&
      offersArray[index].offerValidTill[0] === null
    ) {
      return
    }

    const variantIndex = formik.values.variants.findIndex(
      (obj) => obj.name === variantName
    )
    const variantPrice = formik.values.variants[variantIndex].price

    if (
      variantPrice !== '' &&
      +variantPrice < +offersArray[index].discountedPrice
    ) {
      setError({
        name: false,
        discountedPrice: true,
        message: '*Should be less than regular price.',
      })
      return
    }

    formik.setFieldValue(
      `variants[${variantIndex}].discountedPrice`,
      +offersArray[index].discountedPrice
    )
    formik.setFieldValue(
      `variants[${variantIndex}].discountPercentage`,
      +offersArray[index].discountPercentage
    )
    formik.setFieldValue(
      `variants[${variantIndex}].offerValidTill`,
      offersArray[index].offerValidTill
    )

    const updatedItems = offersArray.map((item, i) =>
      i === index ? { ...item, offerAddded: true } : item
    )
    onChange(updatedItems)
  }

  const handleOnEdit = (index: number): void => {
    const updatedItems = offersArray.map((item, i) =>
      i === index ? { ...item, offerAddded: false } : item
    )
    onChange(updatedItems)
  }

  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 2} mt={{ xs: 2, sm: 0 }}>
        <Label text="Variant Name" htmlFor="variantSelect" />
        <TextField
          select
          fullWidth
          disabled={offersArray[index].offerAddded}
          size="small"
          name="variantName"
          sx={{ marginTop: 0, minWidth: 100 }}
          SelectProps={{ style: { backgroundColor: 'white' } }}
          variant="outlined"
          margin="normal"
          value={offersArray[index].variantName}
          onChange={handleVariantNameChange}
        >
          {list.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 3}>
        <Label text="Discounted Price" />
        <CustomTextField
          type="number"
          min={0}
          disabled={offersArray[index].offerAddded}
          name={`offersArray[${index}].discountedPrice`}
          value={offersArray[index].discountedPrice.toString()}
          onChange={handleDiscountedPriceChange}
          error={error.discountedPrice}
          helperText={error.discountedPrice && error.message}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 2}>
        <Label text="Discount Percentage" />
        <CustomTextField
          type="number"
          min={0}
          max={100}
          name={`offersArray[${index}].discountPercentage`}
          value={offersArray[index].discountPercentage.toString()}
          onChange={handlePercentageChange}
          disabled={offersArray[index].offerAddded}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 3}>
        <Label text="Offer validity" />
        <DateRangeInput
          name={`offersArray[${index}].offerValidity`}
          value={offersArray[index].offerValidTill}
          onChange={(value) => handleDateChange(index, value, 'offerValidTill')}
          disabled={offersArray[index].offerAddded}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 1} mt={{ md: 1.5 }}>
        <div className="flex flex-row items-center">
          {offersArray[index].variantName !== '' &&
            !offersArray[index].offerAddded && (
              <Button
                disableElevation
                variant="contained"
                size="small"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={() => handleOnSave(index)}
              >
                Save
              </Button>
            )}
          {offersArray[index].variantName !== '' &&
            offersArray[index].offerAddded && (
              <Button
                disableElevation
                variant="contained"
                size="small"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={() => handleOnEdit(index)}
              >
                Edit
              </Button>
            )}
          <Box display={{ xs: 'none', sm: 'inline-block' }}>
            <IconButton
              onClick={() => onRemove(index)}
              sx={{ color: 'text.primary' }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>

          <Box display={{ xs: 'inline-block', sm: 'none' }}>
            <Button
              onClick={() => onRemove(index)}
              variant="outlined"
              size="small"
            >
              Remove Offer
            </Button>
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}

export default OfferRow
