import { FC } from 'react'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { InputAdornment, TextField } from '@mui/material'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface DateRangeInputProps {
  name?: string
  value: [Date | null, Date | null]
  onChange: (dates: [Date | null, Date | null]) => void
  disabled?: boolean
}

const DateRangeInput: FC<DateRangeInputProps> = ({
  name,
  value,
  onChange,
  disabled,
}) => {
  const [startDate, endDate] = value

  const handleDateChange = (update: [Date | null, Date | null]): void => {
    onChange(update)
  }

  return (
    <div className="flex flex-col">
      <DatePicker
        name={name}
        dateFormat="dd MMMM yyyy"
        selectsRange={true}
        startDate={startDate || undefined}
        endDate={endDate || undefined}
        onChange={handleDateChange}
        isClearable={true}
        disabled={disabled}
        customInput={
          <TextField
            name={name}
            variant="outlined"
            size="small"
            margin="normal"
            fullWidth
            sx={{ marginTop: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
              className: ' bg-white',
            }}
          />
        }
      />
    </div>
  )
}

export default DateRangeInput
