import React, { useEffect, useState } from 'react'

import { Container, Grid } from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import { TabHeader } from 'pages/dashboard/components/TabHeader'
import { getCategories } from 'services/products'

import CategoryCard, { Category } from './components/CategoryCard'

const CategoryList: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { organizationId } = useAuth()

  useEffect(() => {
    const fetchCategories = async (orgId: number) => {
      try {
        const result = await getCategories(orgId)
        if (result?.status === 'successful') {
          setCategories(result.data)
        }
      } catch (error) {
        console.error('Error fetching categories:', error)
      } finally {
        setLoading(false)
      }
    }

    if (organizationId) fetchCategories(organizationId)
  }, [organizationId])

  if (loading) return <div>Loading categories...</div>

  return (
    <div className="mb-5">
      <TabHeader title="Categories" />
      <Container className="w-full mt-8">
        <Grid container spacing={2}>
          {categories.length > 0 ? (
            categories.map((category) => (
              <Grid item key={category.id} xs={6} md={3}>
                <CategoryCard key={category.id} item={category} />
              </Grid>
            ))
          ) : (
            <div>No categories found</div>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default CategoryList
