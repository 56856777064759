import { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import {
  getCategoryById,
  updateExistingCategory,
} from 'services/products/categories'

import CategoryDetailsSection from './CategoryDetailSection'
import CategoryTitleSection from './CategoryTitleSection'

const DetailsPage = () => {
  const { categoryId } = useParams<{ categoryId: string }>()
  const { organizationId } = useAuth()

  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [description, setDescription] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(-1)
  }

  const handleSaveTitle = async (newTitle: string) => {
    if (!categoryId) {
      console.error('Category ID is missing. Cannot update category.')
      return
    }

    const updateData = { name: newTitle }

    try {
      const result = await updateExistingCategory(categoryId, updateData)

      if (result.status === 'successful') {
        setTitle(newTitle)
      } else {
        console.error('Failed to update category:', result.message)
      }
    } catch (error) {
      console.error('Error during update:', error)
    }
  }

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      if (!categoryId || !organizationId) return

      const result = await getCategoryById(
        organizationId.toString(),
        categoryId
      )

      if (result.status === 'successful') {
        const { name, description, imageUrl } = result.data
        setTitle(name)
        setDescription(description)
        setImageUrl(imageUrl)
      } else {
        console.error('Failed to fetch category:', result.message)
      }
    }

    fetchCategoryDetails()
  }, [categoryId, organizationId])

  return (
    <div>
      <CategoryTitleSection
        title={title}
        onBackClick={handleBackClick}
        onSave={handleSaveTitle}
      />
      <CategoryDetailsSection
        description={description}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setDescription={setDescription}
      />
    </div>
  )
}

export default DetailsPage
