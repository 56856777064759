import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Button, Typography } from '@mui/material'

import Card from 'components/card'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateOrder } from 'services/orders'

interface OrderNotesDetailProps {
  note?: string | null
  orderId: number
  customerId: number
  organizationId: number
}

const OrderNotesDetail: FC<OrderNotesDetailProps> = ({
  note,
  orderId,
  customerId,
  organizationId,
}) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [noteValue, setNoteValue] = useState<string>()
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (note) {
      setNoteValue(note)
    }
  }, [note])

  const handleNoteChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNoteValue(e.target.value)
  }

  const handleEdit = (): void => {
    setEdit(true)
  }

  const handleSave = async (): Promise<void> => {
    if (noteValue?.trim() === note) {
      setEdit(false)
      return
    }

    const result = await updateOrder(orderId, {
      note: noteValue,
      customerId,
      organizationId,
    })
    if (result?.status === 'successful') {
      showSnackbar('Notes updated.', 'success')
    } else if (result?.status === 'failed' && result.message) {
      showSnackbar(result.message, 'error')
      if (note) setNoteValue(note)
    }
    setEdit(false)
  }

  const handleCancel = (): void => {
    setNoteValue(noteValue)
    setEdit(false)
  }
  return (
    <div className="mb-4">
      <div className="mx-5">
        <SubTabHeader
          title="Notes"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
      </div>

      <Card>
        {!edit ? (
          <Typography fontWeight={700}>
            {noteValue ? noteValue : 'N/A'}
          </Typography>
        ) : (
          <>
            <CustomTextField
              id="note"
              value={noteValue}
              onChange={handleNoteChange}
            />
            <div className="mt-4">
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none', mr: 2 }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: 'none' }}
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

export default OrderNotesDetail
