import { Box } from '@mui/material'

import AddButton from './AddButton'

interface TabHeaderProps {
  title: string
}
export const TabHeader: React.FC<TabHeaderProps> = ({ title }): JSX.Element => {
  return (
    <Box
      className="mx-5 mb-4"
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <p className="text-xl font-bold">{title}</p>

      <AddButton buttonText=" + Add New" />
    </Box>
  )
}
