import { getAuthCookie } from 'utils/authCookies'

const analyticsBasePath = `${process.env.REACT_APP_API_BASE_PATH}/analytics`

type GetAnalyticsDataProps = {
  orgId: string | number
  startDate: string
  endDate: string
}

export const getAnalyticsData = async ({
  orgId,
  startDate,
  endDate,
}: GetAnalyticsDataProps) => {
  const token = getAuthCookie('IdToken')
  const url = `${analyticsBasePath}?orgId=${orgId}&startDate=${startDate}&endDate=${endDate}`

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const { data } = await res.json()

    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed', error: res.statusText }
  } catch (error) {
    console.error('Error fetching analytics data:', error)
    throw error
  }
}
