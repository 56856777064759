import { FC, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import Cropper, { Area } from 'react-easy-crop'

import getCroppedImg from 'utils/cropImage'

interface SingleImageCropperProps {
  file: File
  onCropComplete: (croppedFile: File) => void
  onClose?: () => void
  aspect?: number
}

const SingleImageCropper: FC<SingleImageCropperProps> = ({
  file,
  onCropComplete,
  onClose,
  aspect,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [blobUrl, setBlobUrl] = useState<string>()

  useEffect(() => {
    if (file) {
      const newBlobUrl = URL.createObjectURL(file)
      setBlobUrl(newBlobUrl)
      return (): void => {
        URL.revokeObjectURL(newBlobUrl)
      }
    }
  }, [file])

  const onCropCompleteHandler = (
    croppedArea: Area,
    croppedAreaPixels: Area
  ): void => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const handleCrop = async (): Promise<void> => {
    if (croppedAreaPixels && file && blobUrl) {
      const croppedImageBlob = await getCroppedImg(blobUrl, croppedAreaPixels)

      const croppedFile = new File([croppedImageBlob], file.name, {
        type: file.type,
      })

      onCropComplete(croppedFile)
    }
  }

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogContent>
        <Typography variant="h6">Crop Image</Typography>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 400,
            marginTop: 2,
          }}
        >
          <Cropper
            image={blobUrl}
            crop={crop}
            aspect={aspect ?? 3 / 4}
            onCropChange={setCrop}
            onCropComplete={onCropCompleteHandler}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {onClose && <Button onClick={onClose}>Cancel</Button>}
        <Button onClick={handleCrop} variant="contained" color="primary">
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SingleImageCropper
