import React, { useState, useEffect } from 'react'

import { Box, Button, Grid, Menu, MenuItem, Typography } from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import ConfirmationDialog from 'pages/dashboard/components/ConfrimationDialog'
import {
  activateOrganization,
  deactivateOrganization,
  deleteOrganization,
} from 'services/organization'
import {
  AddressJsonType,
  UserOrganizations,
} from 'services/organization/index.types'
import {
  changeRoleInOrganization,
  getStoreUsers,
  inviteAsMember,
} from 'services/store'
import { StoreUsers } from 'services/store/index.type'
import { roleFormat } from 'utils/helper'

import EditStore from './EditStore'
import ManageAccessDialog from './ManageAccessDialog'

interface PrimaryStoreDetailProps {
  store: UserOrganizations
  onDelete: () => void
  onUpdate: () => void
}

const PrimaryStoreDetail: React.FC<PrimaryStoreDetailProps> = ({
  store,
  onDelete,
  onUpdate,
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { organizationId } = useAuth()
  const open = Boolean(anchorEl)
  const { showSnackbar } = useSnackbar()
  const [edit, setEdit] = useState(false)
  const [deactivateDialog, setDeactivateDialog] = useState(false)
  const [activateDialog, setActivateDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [accessDialogOpen, setAccessDialogOpen] = useState(false)
  const [orgUserList, setOrgUserList] = useState<StoreUsers>()

  useEffect(() => {
    async function fetchOrgMembers(): Promise<void> {
      if (organizationId) {
        try {
          const result = await getStoreUsers(organizationId)

          if (result.status === 'successful' && result.data) {
            setOrgUserList(result.data)
          }
        } catch (error) {
          console.error('Error fetching organization members:', error)
        }
      }
    }

    fetchOrgMembers()
  }, [organizationId])

  const role = roleFormat(store.role)
  const address: AddressJsonType = store.organization.address
    ? JSON.parse(store.organization.address)
    : { addressLine1: 'N/A' }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleDeactivateDialog = () => {
    if (store.organizationId) {
      Amplitude.trackEvent('STORE_ACTION_DEACTIVATE_CLICKED', {
        orgId: store.organizationId,
      })
    }
    setDeactivateDialog(true)
  }

  const handleStoreDeactivation = async (): Promise<void> => {
    if (store) {
      const result = await deactivateOrganization(store.organizationId)
      if (result.status === 'successful') {
        showSnackbar('Store Deactivated.', 'success')
        onUpdate()
      } else if (result.status === 'failed') {
        showSnackbar(result.message ?? 'Please try again later.', 'error')
      }
    }
    setDeactivateDialog(false)
    handleClose()
  }

  const handleActivateDialog = () => {
    if (store.organizationId) {
      Amplitude.trackEvent('STORE_ACTION_ACTIVATE_CLICKED', {
        orgId: store.organizationId,
      })
    }
    setActivateDialog(true)
  }

  const handleStoreActivation = async (): Promise<void> => {
    if (store) {
      const result = await activateOrganization(store.organizationId)
      if (result.status === 'successful') {
        showSnackbar('Store Activated.', 'success')
        onUpdate()
      } else if (result.status === 'failed') {
        showSnackbar(result.message ?? 'Please try again later.', 'error')
      }
    }
    setActivateDialog(false)
    handleClose()
  }

  const handleDeleteDialog = () => {
    if (store.organizationId) {
      Amplitude.trackEvent('STORE_ACTION_DELETE_CLICKED', {
        orgId: store.organizationId,
      })
    }
    setDeleteDialog(true)
  }

  const handleStoreDelete = async (): Promise<void> => {
    if (store) {
      const result = await deleteOrganization(store.organizationId)
      if (result.status === 'successful') {
        showSnackbar('Store Deleted.', 'success')
        onDelete()
      } else if (result.status === 'failed') {
        showSnackbar(result.message ?? 'Please try again later.', 'error')
      }
    }
    setDeleteDialog(false)
    handleClose()
  }

  const handleEdit = (): void => {
    if (store.organizationId) {
      Amplitude.trackEvent('STORE_ACTION_EDIT_CLICKED', {
        orgId: store.organizationId,
      })
    }
    setEdit(!edit)
    handleClose()
  }

  const handleAccessControl = (): void => {
    if (store.organizationId) {
      Amplitude.trackEvent('STORE_ACTION_MANAGEACCESS_CLICKED', {
        orgId: store.organizationId,
      })
    }
    setAccessDialogOpen(true)
  }

  const handleAccessDialogClose = (): void => {
    setAccessDialogOpen(false)
  }

  const handleInvite = async (emails: string[]) => {
    try {
      if (!organizationId) throw new Error('Organization ID Not Found')

      const role = 'MEMBER'
      const inviteData = { emails, role, organizationId }

      const response = await inviteAsMember(inviteData)

      if (response.status !== 'successful') {
        throw new Error(response.message || 'Failed')
      }

      const updatedUsers = await getStoreUsers(organizationId)
      if (updatedUsers.status === 'successful' && updatedUsers.data) {
        setOrgUserList(updatedUsers.data)
      }
    } catch (err) {
      console.error('Error in invitation process:', err)
    }
  }
  const handleDeleteUser = (email: string) => {
    console.warn(`User deleted: ${email}`)
  }

  const handleRoleChange = async (
    userId: number,
    newRole: string
  ): Promise<boolean> => {
    try {
      if (!organizationId) throw new Error('Organization ID Not Found')

      const response = await changeRoleInOrganization({
        userId,
        role: newRole,
        organizationId,
      })

      if (response.status === 'successful') {
        showSnackbar('Role updated successfully.', 'success')
        return true
      } else {
        showSnackbar(response.message || 'Failed to update role.', 'error')
        return false
      }
    } catch (error) {
      showSnackbar(
        'Error changing role: ' + (error || 'Unknown error'),
        'error'
      )
      return false
    }
  }

  const handleUpdate = (): void => {
    setEdit(false)
    onUpdate()
  }

  return (
    <div className="mb-5">
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <h2 className="text-lg font-semibold ml-4">Primary Store</h2>
        {!edit && (
          <div>
            <Button
              variant="contained"
              size="small"
              sx={{
                paddingInline: '1rem',
                textTransform: 'none',
                marginRight: '1.25rem',
              }}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Actions
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              {/* <MenuItem onClick={handleClose}>Update Timings</MenuItem> */}
              {!store.organization.deactivated && (
                <MenuItem onClick={handleDeactivateDialog}>Deactivate</MenuItem>
              )}
              {store.organization.deactivated && (
                <MenuItem onClick={handleActivateDialog}>Activate</MenuItem>
              )}
              <MenuItem onClick={handleAccessControl}>Manage Access</MenuItem>
              <MenuItem onClick={handleDeleteDialog}>Delete</MenuItem>
            </Menu>
          </div>
        )}
      </Box>
      <Card>
        <ManageAccessDialog
          open={accessDialogOpen}
          onClose={handleAccessDialogClose}
          storeUsers={orgUserList?.users}
          onInvite={handleInvite}
          onDeleteUser={handleDeleteUser}
          onRoleChange={handleRoleChange}
        />
        <ConfirmationDialog
          open={deactivateDialog}
          title="Deactivate Store"
          content="Are you sure you want to deactivate this store? You can reactivate it later."
          onClose={() => setDeactivateDialog(false)}
          onConfirm={handleStoreDeactivation}
        />
        <ConfirmationDialog
          open={activateDialog}
          title="Activate Store"
          content="Do you want to activate this store?"
          onClose={() => setActivateDialog(false)}
          onConfirm={handleStoreActivation}
        />
        <ConfirmationDialog
          open={deleteDialog}
          title="Delete Store"
          content="This action will permanently delete the store. Are you sure you want to proceed?"
          onClose={() => setDeleteDialog(false)}
          onConfirm={handleStoreDelete}
        />
        {edit && (
          <EditStore
            store={store}
            onUpdate={handleUpdate}
            onEditClose={handleEdit}
          />
        )}
        {!edit && (
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Name
              </Typography>
              <Typography sx={{ fontWeight: '700' }}>
                {store.organization.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Website
              </Typography>
              <Typography sx={{ fontWeight: '700' }}>
                {store.organization.website ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Email
              </Typography>
              <Typography sx={{ fontWeight: '700' }}>
                {store.organization.email ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Phone Number
              </Typography>
              <Typography sx={{ fontWeight: '700' }}>
                {store.organization.phoneNumber ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Address
              </Typography>
              <Typography sx={{ fontWeight: '700' }}>
                {address.addressLine2}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Access Role
              </Typography>
              <Typography sx={{ fontWeight: '700' }}>{role}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Description
              </Typography>
              <Typography sx={{ fontWeight: '700' }}>
                {' '}
                {store.organization.description ?? 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Card>
    </div>
  )
}

export default PrimaryStoreDetail
