import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import theme from 'styles/muiTheme'

const stagePositions1 = [0, 25, 60, 100]
const stagePositions2 = [0, 20, 35, 50, 75, 100]

interface TimelineProps {
  stages: { label: string; date: string }[]
  currentStage: number
}

const Timeline: FC<TimelineProps> = ({ stages, currentStage }): JSX.Element => {
  const stagePositions = stages.length > 4 ? stagePositions2 : stagePositions1

  return (
    <div className="p-9">
      <Box
        position={'relative'}
        display={'flex'}
        sx={{ height: { xs: '15rem', md: 'auto' }, width: { md: '100%' } }}
      >
        <Box
          className=" bg-gray-200 rounded-full absolute"
          height={{ xs: '100%', md: '8px' }}
          width={{ xs: '8px', md: '100%' }}
          sx={{ transform: { md: 'translate(0,-50%)' } }}
        ></Box>
        {stages.map((stage, index) => (
          <Box
            key={index}
            display={'flex'}
            sx={{
              alignItems: 'center',
              flexDirection: { md: 'column' },
              transform: {
                xs: 'translate(0,-50%)',
                md: 'translate(-50%,-50%)',
              },
              gap: { md: '8px' },
              position: 'absolute',
              top: { xs: `${stagePositions[index]}%`, md: 0 },
              left: { xs: '-4px', md: `${stagePositions[index]}%` },
            }}
          >
            {index === currentStage ? (
              <Box
                component="div"
                className="w-4 h-4 rounded-full border"
                sx={{
                  backgroundColor:
                    stages[currentStage].label !== 'CANCELLED'
                      ? theme.palette.primary.main
                      : theme.palette.error.main,
                }}
              ></Box>
            ) : (
              <div className="w-4 h-4 bg-gray-300 rounded-full border"></div>
            )}
          </Box>
        ))}
        {stages.map((stage, index) => (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              flexDirection: { md: 'column' },
              transform: {
                xs: 'translate(0,-50%)',
                md: 'translate(-50%,-50%)',
              },
              top: { xs: `${stagePositions[index]}%`, md: 0 },
              left: { xs: '6%', md: `${stagePositions[index]}%` },
            }}
            className={`gap-2 md:gap-6`}
          >
            {/* Stage label */}
            <Typography
              variant="body2"
              color={index <= currentStage ? 'text.primary' : 'text.secondary'}
            >
              {stage.label}
            </Typography>

            {/* Stage date */}
            <Typography variant="caption" color={'text.secondary'}>
              {stage.date}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default Timeline
