import { createRef, FC, useState } from 'react'

import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import AddressInput, {
  AddressType,
} from 'pages/dashboard/components/AddressInput'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import PhoneNumberInput from 'pages/dashboard/components/PhoneNumberInput'
import { updateOrganization } from 'services/organization'
import {
  AddressJsonType,
  UserOrganizations,
} from 'services/organization/index.types'
import { getRestrictedCountriesList } from 'utils/countries'
import { getCountryCodeAndPhoneNumber } from 'utils/phoneNumber'

interface StoreCreateFormValues {
  name: string
  website: string
  email: string
  phoneNumber: string
  countryCode: string
  address: string
  description: string
}

const phoneRegExp = /^((\+)?(\d{1,3}))?(\d{10})$/

const storeCreateFormSchema = Yup.object().shape({
  name: Yup.string().required('Store name is required'),
  website: Yup.string()
    .url('Enter a valid website URL')
    .required('Website is required'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Enter a valid phone number')
    .required('Phone number is required'),
  address: Yup.string().nullable().optional(),
  description: Yup.string().nullable().optional(),
})

interface EditStoreProps {
  store: UserOrganizations
  onUpdate: () => void
  onEditClose: () => void
}

const EditStore: FC<EditStoreProps> = ({ store, onUpdate, onEditClose }) => {
  const [addressSelect, setAddressSelect] = useState<AddressType | null>(null)
  const { organizationId, updateOrganizationData } = useAuth()
  const { showSnackbar } = useSnackbar()

  const countriesList = getRestrictedCountriesList()

  const phoneNoData = store.organization.phoneNumber
    ? getCountryCodeAndPhoneNumber(store.organization.phoneNumber)
    : undefined

  const address: AddressJsonType = store.organization.address
    ? JSON.parse(store.organization.address)
    : { addressLine1: 'N/A' }

  const initialValues = {
    name: store.organization.name,
    website: store.organization.website ?? '',
    email: store.organization.email ?? '',
    phoneNumber:
      phoneNoData && phoneNoData.phoneNumber
        ? phoneNoData.phoneNumber.toString()
        : '',
    countryCode:
      phoneNoData && phoneNoData.countryCode
        ? '+' + phoneNoData.countryCode.toString()
        : '',
    address: address.addressLine2 ?? '',
    description: store.organization.description ?? '',
  }

  const handleOnChange =
    (setFieldValue: FormikHelpers<StoreCreateFormValues>['setFieldValue']) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      const { name, value } = event.target
      setFieldValue(name, value)
    }

  const handleStoreSetup = async (
    values: StoreCreateFormValues
  ): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('STORE_DETAILS_SAVE_CLICKED', {
        orgId: organizationId,
      })
    }
    const { name, email, phoneNumber, countryCode, description } = values

    const address = addressSelect
      ? {
          addressLine1: '',
          addressLine2: addressSelect.address1,
          city: addressSelect.city,
          state: addressSelect.state,
          country: addressSelect.country,
          pinCode: addressSelect.postalCode,
          addressUrl: addressSelect.addressUrl,
          latitude: addressSelect.latitude,
          longitude: addressSelect.longitude,
        }
      : undefined

    const result = organizationId
      ? await updateOrganization(organizationId, {
          name,
          email,
          phoneNumber: countryCode + phoneNumber,
          address,
          description,
        })
      : { status: 'failed' }

    if (result?.status === 'successful' && result.data) {
      showSnackbar('Store updated successfully.', 'success')
      updateOrganizationData(result.data)
      onUpdate()
    } else if (result?.status === 'failed' && result.message) {
      showSnackbar(result.message, 'error')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={storeCreateFormSchema}
      onSubmit={handleStoreSetup}
    >
      {({
        setFieldValue,
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
      }) => (
        <Form className="w-full">
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Name
              </Typography>
              <CustomTextField
                name="name"
                value={values.name}
                onChange={handleOnChange(setFieldValue)}
                placeholder="Your store name"
                error={touched.name && Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                  marginBottom: '0.5rem',
                }}
              >
                Website
              </Typography>
              <span className=" py-4"> {values.website}</span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Email
              </Typography>
              <CustomTextField
                name="email"
                value={values.email}
                placeholder="support@yourstorename.haulistic.io"
                onChange={handleOnChange(setFieldValue)}
                error={touched.email && Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Phone Number
              </Typography>
              <PhoneNumberInput
                countriesList={countriesList}
                countryCodeName="countryCode"
                phoneNumberName="phoneNumber"
                value={{
                  countryCode: values.countryCode,
                  phoneNumber: values.phoneNumber,
                }}
                error={{
                  countryCode:
                    touched.countryCode && errors.countryCode
                      ? errors.countryCode
                      : '',
                  phoneNumber:
                    touched.phoneNumber && errors.phoneNumber
                      ? errors.phoneNumber
                      : '',
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={{
                  phoneNumber: errors.phoneNumber,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Address
              </Typography>
              <AddressInput
                name="address"
                inputRef={createRef()}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
                onAddressSelect={(address) => {
                  setFieldValue('address', address?.address1)
                  setAddressSelect(address)
                }}
                error={touched.address && Boolean(errors.address)}
                helperText={
                  touched.address && errors.address ? errors.address : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Description
              </Typography>
              <TextField
                name="description"
                className="bg-white"
                value={values.description}
                onChange={(e) => setFieldValue('description', e.target.value)}
                onBlur={handleBlur}
                placeholder="Describe your store in a few words"
                error={touched.description && Boolean(errors.description)}
                helperText={
                  touched.description && errors.description
                    ? errors.description
                    : ''
                }
                multiline
                rows={4}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box display={'flex'} alignItems={'center'} mt={4} columnGap={2}>
            <Button onClick={onEditClose} variant="outlined" size="small">
              Cancel
            </Button>
            <Button type="submit" variant="contained" size="small">
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default EditStore
