import { FC, useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'

import ProductFiles from './Files'

interface FilesDetailsProps {
  media?: string[]
  productId?: number
  onUpdate?: () => void
}

const FilesDetails: FC<FilesDetailsProps> = ({ media, productId }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const { organizationId } = useAuth()
  // const [updatedFiles, setFilesUpdated] = useState<string[]>([])

  const handleEdit = (): void => {
    if (productId && organizationId) {
      Amplitude.trackEvent('PRODUCT_PICTURES_AND_VIDEOS_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setOpenDialog(true)
  }
  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  return (
    <div className="mb-5 mx-5">
      <SubTabHeader
        title="Pictures & Videos"
        buttonText="Edit"
        onClick={handleEdit}
      />
      <Box
        className="my-2 whitespace-nowrap overflow-x-scroll"
        sx={{ scrollbarWidth: 'none' }}
      >
        {media &&
          media.map((url, index) => (
            <Card key={index} className="h-52 w-36 inline-block mr-2 rounded">
              <CardMedia
                component="img"
                image={url}
                alt={`media-${index}`}
                className="w-full h-52 object-contain"
              />
            </Card>
          ))}
      </Box>
      {openDialog && (
        <Dialog open onClose={handleCloseDialog} maxWidth={'md'} fullWidth>
          <DialogContent>
            <Box>
              <ProductFiles productId={productId} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default FilesDetails
