import { Typography, Box, Container, Divider } from '@mui/material'

import Footer from 'pages/home/components/Footer'

export const ShippingPolicy: React.FC = () => {
  return (
    <Box className="mt-20">
      {/* Header Section */}
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" gutterBottom>
            Shipping and Delivery Policy
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Effective Date: 6 August 2024
          </Typography>
          <Divider />
          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              Digital Delivery
            </Typography>
            <Typography paragraph>
              <strong>Immediate Access:</strong> Once your payment is
              successfully processed, you will instantly gain access to the
              Haulistic services you’ve subscribed to. A confirmation email
              containing login details and setup instructions will be sent to
              the email address provided during registration.
            </Typography>
            <Typography paragraph>
              <strong>No Physical Shipping:</strong> Haulistic services are
              completely digital and accessed via the web or cloud-based
              applications. As such, there are no physical goods to ship,
              eliminating any waiting time or shipping fees.
            </Typography>
            <Typography paragraph>
              <strong>Account Setup:</strong> If you are a new user, the
              registration process will guide you through creating an account.
              This account serves as your hub for accessing services, managing
              your subscription, and contacting support.
            </Typography>
            <Typography paragraph>
              <strong>Service Enhancements:</strong> All software updates, new
              features, or enhancements are delivered digitally. These updates
              will either be automatically applied to your account or made
              available for activation, ensuring that you always have access to
              the latest version of Haulistic.
            </Typography>
          </Box>
          <Box my={3}>
            <Typography variant="h6" gutterBottom>
              Support and Assistance
            </Typography>
            <Typography paragraph>
              <strong>Customer Support:</strong> We’re here to help! If you
              encounter any issues accessing or using Haulistic, our support
              team is readily available. Reach out to us at{' '}
              <a href="mailto:support@haulistic.io">support@haulistic.io</a>,
              and we’ll ensure that your experience is smooth and seamless.
            </Typography>
          </Box>
          <Box my={3}>
            <Typography paragraph>
              If you have any additional questions or concerns about our
              shipping policy or how to access your services, please don’t
              hesitate to contact us. We’re here to assist you at every step of
              your journey with Haulistic.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  )
}
