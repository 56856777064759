import { useFormikContext } from 'formik'

import Card from 'components/card'
import Label from 'components/forms/Label'
import TagInput from 'pages/dashboard/components/TagInput'

import { ProductDetailFormValues } from '../pages/AddProductPage'

const NotesAndTags = (): JSX.Element => {
  const formik = useFormikContext<ProductDetailFormValues>()

  const handleTagsChange = (tags: number[]): void => {
    formik.setFieldValue('tagIds', tags)
  }

  return (
    <div className=" mb-5">
      <h2 className="text-lg font-semibold ml-4">Notes & Tags (Optional)</h2>
      <p className="text-sm text-gray-500 mb-2 ml-4">
        Notes field is for internal use only. Customers will not see these
        notes.
      </p>
      <Card>
        <div className="mb-4">
          <Label text="Notes" />
          <textarea
            name="notes"
            className="w-full border border-gray-300 rounded py-2 px-3"
            rows={3}
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <Label text="Tags" />
          <TagInput entity="product" onChange={handleTagsChange} />
        </div>
      </Card>
    </div>
  )
}

export default NotesAndTags
