import { getToken } from 'utils/authCookies'

import { PhonePeAccountParams } from './index.types'

type PhonePeCreateAccountResponse = {
  status: 'successful' | 'failed'
  data?: {
    success: boolean
  }
  message?: string
}

const paymentsBasePath = `${process.env.REACT_APP_API_BASE_PATH}/payments`

export const createPhonePeAccount = async (
  organizationId: number,
  accountData: PhonePeAccountParams
): Promise<PhonePeCreateAccountResponse> => {
  const token = await getToken()

  const body = {
    ...accountData,
    organizationId,
  }

  try {
    const response = await fetch(`${paymentsBasePath}/account/phonepe`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    })

    const responseData = await response.json()

    if (response.ok) {
      return { status: 'successful', data: responseData.data }
    }

    return { status: 'failed', message: responseData.message }
  } catch (error) {
    console.error('Error creating PhonePe account:', error)
    return { status: 'failed', message: 'An unexpected error occurred' }
  }
}
