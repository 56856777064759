import { getToken } from 'utils/authCookies'
import { buildCustomerUrl } from 'utils/helper'

import {
  Order,
  OrderQueryParams,
  OrderStatus,
  UpdateOrderParams,
} from './index.type'

const ordersBasePath = `${process.env.REACT_APP_API_BASE_PATH}/orders`

export const getOrders = async (
  query: OrderQueryParams
): Promise<{ total: number; orders: Order[] } | undefined> => {
  const token = await getToken()
  const url = buildCustomerUrl(ordersBasePath, query)
  try {
    const res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    if (res.ok) {
      const { data } = await res.json()
      return data
    }
  } catch (err) {
    console.error(err)
  }
}

export const getOrderById = async (
  orderId: string
): Promise<{ status: string; data?: Order; message?: string } | undefined> => {
  const token = await getToken()
  try {
    const res = await fetch(`${ordersBasePath}/${orderId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    })

    const { data, message } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
  }
}

export const updateStatus = async (
  orderId: string,
  updateData: { status: string; description?: string }
): Promise<
  { status: string; data?: OrderStatus; message?: string } | undefined
> => {
  const token = await getToken()
  try {
    const res = await fetch(`${ordersBasePath}/${orderId}/status`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(updateData),
    })

    const { data, message } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
  }
}

export const updateOrder = async (
  orderId: number,
  updateData: UpdateOrderParams
): Promise<{ status: string; data?: Order; message?: string } | undefined> => {
  const token = await getToken()
  try {
    const res = await fetch(`${ordersBasePath}/${orderId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify(updateData),
    })

    const { data, message } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed', message }
  } catch (err) {
    console.error(err)
  }
}
