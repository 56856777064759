import React from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface EditorProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
}

const QuillEditor: React.FC<EditorProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const handleChange = (
    content: string,
    delta: unknown,
    source: unknown,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    if (source === 'user') {
      onChange(editor.getHTML())
    }
  }

  return (
    <ReactQuill
      className="bg-white"
      theme="snow"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
  )
}

export default QuillEditor
