import { FC, useState } from 'react'

import { Typography } from '@mui/material'
import DOMPurify from 'dompurify'

interface TextCollapseProps {
  text: string
}

const TextCollapse: FC<TextCollapseProps> = ({ text }) => {
  const [showFullText, setShowFullText] = useState(false)

  const handleToggleText = (): void => {
    setShowFullText((prevShowFullText) => !prevShowFullText)
  }

  return (
    <div>
      <Typography
        sx={{
          fontWeight: 700,
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: showFullText ? 'none' : 3,
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(text),
        }}
      />
      {text.trim() !== '' && (
        <button
          onClick={handleToggleText}
          className=" border border-solid rounded text-xs px-2 py-1 mt-1 hover:bg-slate-50"
        >
          {showFullText ? 'See Less' : 'See More'}
        </button>
      )}
    </div>
  )
}

export default TextCollapse
