import React from 'react'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material'
import { FormikProps } from 'formik'

import Label from 'components/forms/Label'
import CustomTextField from 'pages/dashboard/components/CustomTextField'

import { ProductDetailFormValues } from '../pages/AddProductPage'

interface VariantsRowProps {
  type: string
  index: number
  onRemove: (index: number) => void
  formik: FormikProps<ProductDetailFormValues>
  onVariantAdd?: (val: boolean) => void
}

type VariantType = {
  type: string
  name: string
  price: string
  discountedPrice: string
  quantity: string
  costPerUnit: string
  discountPercentage: string
}

const VariantRow: React.FC<VariantsRowProps> = ({
  index,
  onRemove,
  formik,
  onVariantAdd,
}) => {
  const isPreviewOn = formik.values.name !== ''
  const handleRemove = (index: number): void => {
    if (formik.values.variants.length === 1) {
      onVariantAdd?.(false)
      formik.setFieldValue('price', formik.values.variants[index].price)
      formik.setFieldValue('quantity', formik.values.variants[index].quantity)
      formik.setFieldValue(
        'costPerUnit',
        formik.values.variants[index].costPerUnit
      )
      formik.setTouched({ price: false, quantity: false })
    }
    onRemove(index)
  }
  return (
    <Grid container alignItems={'center'} spacing={2}>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 3}>
        <Label text="Variant Name" htmlFor="name" />
        <CustomTextField
          name={`variants.${index}.name`}
          value={formik.values.variants?.[index].name}
          onChange={formik.handleChange}
          error={
            formik.touched.variants?.[index] &&
            formik.touched.variants?.[index].name &&
            formik.errors.variants?.[index]
              ? Boolean((formik.errors.variants[index] as VariantType).name)
              : false
          }
          helperText={
            formik.touched.variants?.[index]?.name &&
            formik.errors.variants?.[index]
              ? (formik.errors.variants[index] as VariantType).name
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 2}>
        <Label text="Type" htmlFor="type" />
        <TextField
          select
          fullWidth
          size="small"
          name={`variants.${index}.type`}
          sx={{ marginTop: 0, minWidth: 100 }}
          SelectProps={{ style: { backgroundColor: 'white' } }}
          variant="outlined"
          margin="normal"
          value={formik.values.variants?.[index].type}
          onChange={formik.handleChange}
          error={Boolean(
            formik.touched.variants?.[index]?.type &&
              formik.errors.variants?.[index]
              ? Boolean((formik.errors.variants[index] as VariantType).type)
              : false
          )}
        >
          <MenuItem value={'color'}>Color</MenuItem>
          <MenuItem value={'size'}>Size</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 2}>
        <Label text="Selling Price" htmlFor="price" />
        <CustomTextField
          name={`variants.${index}.price`}
          min={0}
          type="number"
          value={formik.values.variants?.[index].price}
          onChange={formik.handleChange}
          error={
            formik.touched.variants?.[index] &&
            formik.touched.variants?.[index].price &&
            formik.errors.variants?.[index]
              ? Boolean((formik.errors.variants[index] as VariantType).price)
              : false
          }
          helperText={
            formik.touched.variants?.[index]?.price &&
            formik.errors.variants?.[index]
              ? (formik.errors.variants[index] as VariantType).price
              : ''
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 2}>
        <Label text="Quantity in Stock" htmlFor="quantity" />
        <CustomTextField
          name={`variants.${index}.quantity`}
          value={formik.values.variants?.[index].quantity}
          onChange={formik.handleChange}
          error={
            formik.touched.variants?.[index] &&
            formik.touched.variants?.[index].quantity &&
            formik.errors.variants?.[index]
              ? Boolean((formik.errors.variants[index] as VariantType).quantity)
              : false
          }
          helperText={
            formik.touched.variants?.[index]?.quantity &&
            formik.errors.variants?.[index]
              ? (formik.errors.variants[index] as VariantType).quantity
              : ''
          }
          type="number"
          min={0}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={isPreviewOn ? 4 : 2}>
        <Label text="Cost Per Unit" htmlFor="costPerUnit" />
        <CustomTextField
          name={`variants.${index}.costPerUnit`}
          value={formik.values.variants?.[index].costPerUnit}
          onChange={formik.handleChange}
          error={
            formik.touched.variants?.[index] &&
            formik.touched.variants?.[index].costPerUnit &&
            formik.errors.variants?.[index]
              ? Boolean(
                  (formik.errors.variants[index] as VariantType).costPerUnit
                )
              : false
          }
          helperText={
            formik.touched.variants?.[index]?.costPerUnit &&
            formik.errors.variants?.[index]
              ? (formik.errors.variants[index] as VariantType).costPerUnit
              : ''
          }
          type="number"
          min={0}
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={isPreviewOn ? 4 : 1}
        display={{ xs: 'none', sm: 'inline-block' }}
        mt={{ md: 1.5 }}
      >
        <IconButton
          onClick={() => handleRemove(index)}
          sx={{ color: 'text.primary' }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} display={{ xs: 'block', sm: 'none' }}>
        <Button
          onClick={() => handleRemove(index)}
          variant="outlined"
          size="small"
        >
          Remove Variant
        </Button>
      </Grid>
    </Grid>
  )
}

export default VariantRow
