// SignIn.tsx
import React, { useState } from 'react'

import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Navigate } from 'react-router-dom'

import LogInForm from 'components/forms/LoginForm'
import { useAuth } from 'hooks/useAuth'
import Footer from 'pages/home/components/Footer'

import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm'

const SignIn: React.FC = () => {
  const [activeForm, setActiveForm] = useState<'signin' | 'forgot'>('signin')

  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Navigate to="/dashboard/home" replace={true} />

  const handleFormChange = (form: 'signin' | 'forgot'): void => {
    setActiveForm(form)
  }
  return (
    <div>
      <Helmet>
        <title>Sign In | Haulistic</title>
      </Helmet>
      <div className="w-full flex flex-col items-center min-h-screen">
        <div className="mt-8 max-w-md w-full">
          {activeForm === 'signin' && (
            <LogInForm
              onForgotPassword={() => handleFormChange('forgot')}
              // onSignIn={handleSignIn}
            />
          )}
          {activeForm === 'forgot' && (
            <ForgotPasswordForm
              onActiveForm={() => handleFormChange('signin')}
            />
          )}
        </div>
      </div>
      <Box
        sx={{
          background: 'rgba(0, 137, 123, 0.1)',
          width: '100%',
        }}
      >
        <Footer />
      </Box>
    </div>
  )
}

export default SignIn
