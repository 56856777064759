// utils/validation.js
export const validateFile = (
  file: File
): Promise<{
  valid: boolean
  error: string
}> => {
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
  const maxFileSize = 5 * 1024 * 1024 // Updated to 5MB

  if (!allowedTypes.includes(file.type)) {
    return Promise.resolve({
      valid: false,
      error: 'Invalid file type. Only JPEG, PNG, and SVG are allowed.',
    })
  }

  if (file.size > maxFileSize) {
    return Promise.resolve({
      valid: false,
      error: 'File size exceeds the maximum limit of 5MB.', // Updated error message
    })
  }

  // No dimension check since cropping will handle it
  return Promise.resolve({
    valid: true,
    error: '',
  })
}

interface ProductImageValidation {
  valid: boolean
  errorType?: string
  message: string
}

export async function validateProductImage(
  file: File
): Promise<ProductImageValidation> {
  const maxFileSize = 5 * 1024 * 1024 // Updated to 5MB
  const allowedFormats = ['image/jpeg', 'image/png']

  if (!allowedFormats.includes(file.type)) {
    return {
      valid: false,
      message: 'Invalid file format. Only JPEG and PNG are allowed.',
    }
  }

  // Check file size
  if (file.size > maxFileSize) {
    return { valid: false, message: 'File size exceeds the limit of 5 MB.' } // Updated message
  }

  // No need to check dimensions here since cropping will handle it
  return { valid: true, message: 'Image is valid.' }
}

export const validateCategoryImage = (
  file: File
): Promise<{ valid: boolean; error?: string }> => {
  return new Promise((resolve) => {
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg']
    const maxSizeInBytes = 5 * 1024 * 1024 // Updated to 5MB

    // Check file type
    if (!validFileTypes.includes(file.type)) {
      return resolve({
        valid: false,
        error: 'Invalid file type. Please upload a PNG or JPEG image.',
      })
    }

    // Check file size
    if (file.size > maxSizeInBytes) {
      return resolve({
        valid: false,
        error: 'File size should be less than 5MB.', // Updated message
      })
    }

    // No dimension check since cropping is handled post-upload
    resolve({ valid: true })
  })
}
