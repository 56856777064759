import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

const Analytics = (): JSX.Element => {
  const { organization } = useAuth()
  return (
    <div>
      <Helmet>
        <title>
          Analytics
          {organization && organization.name ? ` | ${organization.name}` : ''}
        </title>
      </Helmet>
      <Outlet />
    </div>
  )
}

export default Analytics
