import { useEffect, useState } from 'react'

import { Button } from '@mui/material'
import { useFormikContext } from 'formik'

import OfferRow from './OfferRow'
import { ProductDetailFormValues } from '../pages/AddProductPage'

type OffersArray = {
  variantName: string
  discountedPrice: string | number
  discountPercentage: string | number
  offerValidTill: [Date | null, Date | null]
  offerAddded: boolean
}

const OfferTable = (): JSX.Element => {
  const [nameList, setNameList] = useState<string[]>([])
  const formik = useFormikContext<ProductDetailFormValues>()

  const [offersArray, setOffersArray] = useState<OffersArray[]>([
    {
      variantName: '',
      discountedPrice: '',
      discountPercentage: '',
      offerValidTill: [null, null],
      offerAddded: false,
    },
  ])

  useEffect(() => {
    if (formik.values.variants) {
      const list = formik.values.variants.map((variant) => variant.name)
      setNameList(list)
      const nameSet = new Set(list)
      const result = offersArray.filter((obj) => nameSet.has(obj.variantName))
      if (result.length === 0)
        result.push({
          variantName: '',
          discountedPrice: '',
          discountPercentage: '',
          offerValidTill: [null, null],
          offerAddded: false,
        })
      setOffersArray(result)
    }
  }, [formik.values.variants])

  const handleRemove = (index: number): void => {
    const variantName = offersArray[index].variantName

    if (variantName !== '') {
      const variantIndex = formik.values.variants.findIndex(
        (obj) => obj.name === offersArray[index].variantName
      )

      formik.setFieldValue(`variants[${variantIndex}].discountedPrice`, '')
      formik.setFieldValue(`variants[${variantIndex}].discountPercentage`, '')
      formik.setFieldValue(`variants[${variantIndex}].offerValidTill`, [
        null,
        null,
      ])
    }

    setOffersArray((prev) => {
      const prevArray = [...prev]
      prevArray.splice(index, 1)
      return prevArray
    })
  }

  const hanldeAddMoreOffer = (): void => {
    setOffersArray((prev) => [
      ...prev,
      {
        variantName: '',
        discountedPrice: '',
        discountPercentage: '',
        offerValidTill: [null, null],
        offerAddded: false,
      },
    ])
  }

  return (
    <div>
      {offersArray.map((el, index) => {
        return (
          <OfferRow
            key={index}
            list={nameList}
            offersArray={offersArray}
            index={index}
            onRemove={handleRemove}
            onChange={setOffersArray}
            formik={formik}
          />
        )
      })}
      <Button
        size="small"
        variant="outlined"
        onClick={hanldeAddMoreOffer}
        sx={{ mt: 1.5 }}
      >
        + Add more Offers
      </Button>
    </div>
  )
}

export default OfferTable
