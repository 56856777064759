import { useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { LineChart, PieChart } from '@mui/x-charts'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { getAnalyticsData } from 'services/analytics'

import HeatMap from '../components/HeatMap'

interface AnalyticsData {
  businessMetrics: {
    totalOrders: number
    totalRevenue: number
    averageOrderValue: number
    newCustomers: number
  }
  websiteMetrics: {
    uniqueVisitors: number
    totalPageViews: number
  }
  revenueOverTime: Record<string, number>
  pageViewsOverTime: Record<string, number>
  topReferrers: Record<string, number>
  demographics: Record<string, number>
}

const products = [
  'All Products',
  'A Portfolio of Small Bets — The Course',
  'Iowa — End Grain Cutting Board',
  'California — End Grain Cutting Board',
  'Texas — End Grain Cutting Board',
  'Montana — End Grain Cutting Board',
]

const getDateRange = (
  timeRange: string
): { startDate: string; endDate: string } => {
  const today = new Date()
  let startDate = new Date()
  let endDate = new Date(today)

  switch (timeRange) {
    case 'Last 30 days':
      startDate.setDate(today.getDate() - 30)
      endDate.setDate(today.getDate() + 1)
      break

    case 'Last month': {
      const currentMonth = today.getMonth()
      const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1
      const year =
        currentMonth === 0 ? today.getFullYear() - 1 : today.getFullYear()

      startDate = new Date(year, lastMonth, 1)
      endDate = new Date(year, lastMonth + 1, 0)
      break
    }

    case 'Last 3 months': {
      const currentMonth = today.getMonth()
      startDate = new Date(today.getFullYear(), currentMonth - 3, 1)
      endDate = new Date(today.getFullYear(), currentMonth, 0)
      break
    }

    case 'This year':
      startDate = new Date(today.getFullYear(), 0, 1)
      break

    case 'Last year':
      startDate = new Date(today.getFullYear() - 1, 0, 1)
      endDate = new Date(today.getFullYear() - 1, 11, 31)
      break

    default:
      startDate = new Date(2020, 0, 1)
      endDate.setDate(today.getDate() + 1)
      break
  }

  const formatDate = (date: Date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
      date.getDate()
    ).padStart(2, '0')}`

  return { startDate: formatDate(startDate), endDate: formatDate(endDate) }
}

const AnalyticsPage = (): JSX.Element => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null)
  const { organizationId, organization } = useAuth()
  const [time, setTime] = useState<string>('All Time')
  const [selectedProducts, setSelectedProducts] = useState<string[]>([
    'All Products',
  ])

  const handleChange = (event: SelectChangeEvent): void => {
    setTime(event.target.value)
  }

  const handleProductsChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    setSelectedProducts(typeof value === 'string' ? value.split(',') : value)
  }

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      if (!organizationId) {
        console.error('Organization ID is null')
        return
      }

      const { startDate, endDate } = getDateRange(time)

      try {
        const response = await getAnalyticsData({
          orgId: organizationId,
          startDate,
          endDate,
        })

        if (response.status === 'successful') {
          setAnalyticsData(response.data)
          //  console.warn('analyticsData', analyticsData)
        } else {
          console.error('Failed to fetch analytics data:', response.error)
        }
      } catch (error) {
        console.error('Error fetching analytics data:', error)
      }
    }

    if (organizationId) {
      fetchAnalyticsData()
    }
  }, [organizationId, time])

  useEffect(() => {
    async function get(): Promise<void> {
      const result = organizationId ? true : false
      if (result) setTableActive(true)
    }
    get()
  }, [organizationId])

  if (organization === null) {
    return <SetupStorePrompt />
  }

  const businessMetrics = analyticsData?.businessMetrics
  const websiteMetrics = analyticsData?.websiteMetrics
  const revenueData = Object.keys(analyticsData?.revenueOverTime ?? {}).reduce(
    (acc, date) => {
      const [day, month, year] = date.split('/')
      const formattedDate = `${year}-${month}-${day}`

      acc[formattedDate] = analyticsData?.revenueOverTime?.[date] ?? 0
      return acc
    },
    {} as Record<string, number>
  )
  const pageViewsData = Object.keys(
    analyticsData?.pageViewsOverTime ?? {}
  ).reduce(
    (acc, date) => {
      const [day, month, year] = date.split('/')
      const formattedDate = `${year}-${month}-${day}`

      acc[formattedDate] = analyticsData?.pageViewsOverTime?.[date] ?? 0
      return acc
    },
    {} as Record<string, number>
  )

  const mapData = analyticsData?.demographics
    ? { ...analyticsData.demographics }
    : {}

  const referrersData = analyticsData?.topReferrers
    ? Object.keys(analyticsData.topReferrers)
        .filter((key) => key !== '')
        .map((key, index, arr) => {
          const total = arr.reduce(
            (sum, key) => sum + analyticsData.topReferrers[key],
            0
          )
          const percentage = (analyticsData.topReferrers[key] / total) * 100

          return {
            id: index,
            value: Number(percentage.toFixed(2)),
            label: key,
            color: [
              '#87CEFA',
              '#4682B4',
              '#1E90FF',
              '#5F9EA0',
              '#00CED1',
              '#6495ED',
            ][index % 6],
          }
        })
    : []

  return (
    <div>
      <div className="mb-5">
        {tableActive && organizationId && (
          <div>
            <div className="mb-4 mx-5">
              <p className="text-xl font-bold">Analytics</p>
            </div>
            <div className=" flex justify-between items-center mx-5">
              <SubTabHeader title="Business Metrics" />
              <div className="flex items-center ">
                <FormControl
                  sx={{
                    minWidth: 80,
                    padding: 0,
                    paddingRight: '16px',
                    display: 'none',
                  }}
                  size="small"
                >
                  <Select
                    id="product-select"
                    multiple
                    value={selectedProducts}
                    onChange={handleProductsChange}
                    displayEmpty
                    renderValue={(selected) =>
                      selected.length === 0
                        ? 'Select products...'
                        : selected.length === 1
                          ? selected[0]
                          : `${selected.length} products selected`
                    }
                    input={<OutlinedInput />}
                    sx={{ fontSize: '0.75rem', padding: 0 }}
                  >
                    {products.map((product) => (
                      <MenuItem
                        key={product}
                        value={product}
                        sx={{ padding: '0px 4px 0px 0px' }}
                      >
                        <Checkbox
                          checked={selectedProducts.indexOf(product) > -1}
                          size="small"
                        />
                        <ListItemText
                          primary={product}
                          primaryTypographyProps={{ fontSize: '0.7rem' }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 100, padding: 0 }} size="small">
                  <Select
                    id="time"
                    value={time}
                    onChange={handleChange}
                    sx={{ fontSize: '0.75rem', padding: 0 }}
                  >
                    <MenuItem value="Last 30 days" sx={{ fontSize: '0.75rem' }}>
                      Last 30 days
                    </MenuItem>
                    <MenuItem value="Last month" sx={{ fontSize: '0.75rem' }}>
                      Last month
                    </MenuItem>
                    <MenuItem
                      value="Last 3 months"
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Last 3 months
                    </MenuItem>
                    <MenuItem value="This year" sx={{ fontSize: '0.75rem' }}>
                      This year
                    </MenuItem>
                    <MenuItem value="Last year" sx={{ fontSize: '0.75rem' }}>
                      Last year
                    </MenuItem>
                    <MenuItem value="All Time" sx={{ fontSize: '0.75rem' }}>
                      All Time
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <Card>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Label text="Total Number of Orders" />
                  <Typography color={'primary'} fontWeight={600}>
                    {businessMetrics?.totalOrders ?? 'Loading...'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Total Revenue" />
                  <Typography color={'primary'} fontWeight={600}>
                    {businessMetrics?.totalRevenue !== undefined
                      ? `₹${businessMetrics.totalRevenue.toLocaleString()}`
                      : 'Loading...'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Average Order Value" />
                  <Typography color={'primary'} fontWeight={600}>
                    {businessMetrics?.averageOrderValue !== undefined
                      ? `₹${businessMetrics.averageOrderValue.toFixed(2)}`
                      : 'Loading...'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Number of New Customers" />
                  <Typography color={'primary'} fontWeight={600}>
                    {businessMetrics?.newCustomers ?? 'Loading...'}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <div className="mx-5">
              <SubTabHeader title="Website Metrics" />
            </div>
            <Card>
              <Grid container spacing={2} rowSpacing={4}>
                <Grid item xs={12} md={6}>
                  <Label text="Unique Visitors" />
                  <Typography color={'primary'} fontWeight={600}>
                    {websiteMetrics?.uniqueVisitors ?? 'Loading...'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label text="Total page views" />
                  <Typography color={'primary'} fontWeight={600}>
                    {websiteMetrics?.totalPageViews ?? 'Loading...'}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <div className="p-5">
              <Grid container spacing={2}>
                {/* Revenue Over Time */}
                <Grid item xs={12} md={6}>
                  <Paper className="border rounded p-4">
                    <p className="text-lg font-bold">Revenue Over Time</p>
                    <LineChart
                      xAxis={[
                        {
                          scaleType: 'time',
                          data: Object.keys(revenueData).map(
                            (el) => new Date(el)
                          ),
                          tickInterval: Object.keys(revenueData).map(
                            (el) => new Date(el)
                          ),
                          valueFormatter: (date) => {
                            const d = new Date(date)
                            const day = d.getDate()
                            const month = d.toLocaleDateString('en-US', {
                              weekday: 'short',
                            })
                            return `${day}\n${month}`
                          },
                        },
                      ]}
                      yAxis={[
                        {
                          min: 0,
                        },
                      ]}
                      series={[
                        {
                          data: Object.values(revenueData),
                          label: 'Revenue',
                        },
                      ]}
                      height={300}
                    />
                  </Paper>
                </Grid>
                {/* Customer Engagement: Page Views Over Time */}
                <Grid item xs={12} md={6}>
                  <Paper className="border rounded p-4">
                    <p className="text-lg font-bold">
                      Customer Engagement: Page Views Over Time
                    </p>
                    <LineChart
                      xAxis={[
                        {
                          scaleType: 'time',
                          data: Object.keys(pageViewsData).map(
                            (el) => new Date(el)
                          ),
                          tickInterval: Object.keys(pageViewsData).map(
                            (el) => new Date(el)
                          ),
                          valueFormatter: (date) => {
                            const d = new Date(date)
                            const day = d.getDate()
                            const month = d.toLocaleDateString('en-US', {
                              weekday: 'short',
                            })
                            return `${day}\n${month}`
                          },
                        },
                      ]}
                      yAxis={[
                        {
                          min: 0,
                        },
                      ]}
                      series={[
                        {
                          data: Object.values(pageViewsData),
                          label: 'Page Views',
                        },
                      ]}
                      height={300}
                    />
                  </Paper>
                </Grid>
                {/* Referrers (Top Referrers Driving Traffic) */}
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height="100%"
                    className="border rounded p-4"
                  >
                    <p className="text-lg font-bold">
                      Top Referrers Driving Traffic
                    </p>
                    <Box
                      display="flex"
                      flexDirection={{ xs: 'column', md: 'row' }}
                      alignItems={{ xs: 'center', md: 'flex-start' }}
                      justifyContent="space-between"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width={{ xs: '100%', md: '60%' }}
                      >
                        <PieChart
                          series={[
                            {
                              data: referrersData,
                              arcLabel: ({ value }) => `${value}%`,
                            },
                          ]}
                          height={300}
                          legend={{
                            hidden: true,
                          }}
                        />
                      </Box>

                      <Box className="mt-2 md:mt-6 md:ml-0 md:mr-12 flex flex-col items-center md:items-start justify-center md:justify-start p-2 bg-gray-100 rounded-lg w-full md:w-2/5">
                        {referrersData.map((item, index) => (
                          <Box key={index} className="flex items-center mb-2">
                            <Box
                              className="w-5 h-5 rounded-full mr-2"
                              sx={{ backgroundColor: item.color }}
                            />

                            <Box component="span" className="hidden md:inline">
                              {item.label}
                            </Box>
                            <Box component="span" className="md:hidden">
                              {item.label.length > 30 ? (
                                <Tooltip title={item.label} arrow>
                                  <span>{`${item.label.slice(0, 30)}...`}</span>
                                </Tooltip>
                              ) : (
                                <span>{item.label}</span>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* Demographics (Global Customer Demographics) */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    className="border rounded p-4"
                  >
                    <p className="text-lg font-bold">
                      Global Customer Demographics
                    </p>
                    <Box>
                      <HeatMap data={mapData} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalyticsPage
