import { FC } from 'react'

import { Box, Card, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import ImageComponent from 'components/ImgPlaceholder'

import CategoryEditBtn from './CategoryEditBtn'

export type Category = {
  id: number
  name: string
  createdAt: Date
  updatedAt: Date
  organizationId: number
  imageUrl: string | undefined
}

interface CategoryCardProps {
  item: Category
}

const CategoryCard: FC<CategoryCardProps> = ({ item }) => {
  const navigate = useNavigate()

  const handleEditClick = () => {
    navigate(`${item.id}`)
  }

  return (
    <Box>
      <Card
        sx={{
          margin: 'auto',
          marginBottom: 2,
          position: 'relative',
          '&:hover .category-edit-btn': { opacity: 0.5 },
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '300px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageComponent imageUrl={item.imageUrl} altText={item.name} />
          <CategoryEditBtn onClick={handleEditClick} />
        </div>
      </Card>
      <Typography
        variant="body1"
        color="text.secondary"
        className="text-center"
      >
        {item.name.toUpperCase()}
      </Typography>
    </Box>
  )
}

export default CategoryCard
