export const dateTimeFormat = (dateString: string): string => {
  const date = new Date(dateString)

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })

  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  return `${formattedDate}, ${formattedTime}`
}

export const shortDateFormat = (dateString: string): string => {
  const date = new Date(dateString)

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })

  return formattedDate
}
