import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Typography, TextField, Button } from '@mui/material'
import * as Yup from 'yup'

const validationSchema = Yup.object({
  name: Yup.string().required('*Category name is required'),
})

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'

interface TitleSectionProps {
  title: string
  onBackClick: () => void
  onSave: (newTitle: string) => void
}

const CategoryTitleSection = ({
  title,
  onBackClick,
  onSave,
}: TitleSectionProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedTitle, setEditedTitle] = useState(title)
  const [isHovered, setIsHovered] = useState(false)
  const [error, setError] = useState('')
  const { organizationId } = useAuth()

  const handleEdit = () => {
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_TITLE_EDIT_CLICKED', {
        orgId: organizationId,
      })
    }
    setIsEditing(true)
  }

  const handleSave = async (): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_TITLE_SAVE_CLICKED', {
        orgId: organizationId,
      })
    }
    try {
      await validationSchema.validate({ name: editedTitle })
      onSave(editedTitle)
      setIsEditing(false)
      setError('')
    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        setError(validationError.message)
      }
    }
  }

  const handleCancel = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_TITLE_CANCEL_CLICKED', {
        orgId: organizationId,
      })
    }
    setIsEditing(false)
    setEditedTitle(title)
    setError('')
  }

  if (title !== editedTitle && !isEditing) {
    setEditedTitle(title)
  }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
      className="mx-3 mt-4"
    >
      <IconButton onClick={onBackClick} sx={{ color: 'text.primary' }}>
        <ArrowBackIcon />
      </IconButton>

      {isEditing ? (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <TextField
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            size="small"
            fullWidth
            error={!!error}
            helperText={error}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ ml: 1 }}
            size="small"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            sx={{ ml: 1 }}
            size="small"
          >
            Cancel
          </Button>
        </Box>
      ) : (
        <Box
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Typography variant="h6">{editedTitle}</Typography>
          {isHovered && (
            <IconButton onClick={handleEdit} sx={{ ml: 1 }}>
              <EditIcon sx={{ color: 'black' }} />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CategoryTitleSection
