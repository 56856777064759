import { Box } from '@mui/material'

import QuillEditor from 'components/quill'

interface NotesEditorProps {
  notes?: string // Initial content
  onNotesChange: (note: string) => void // Callback to update notes
}

const NotesEditor = ({ notes = '', onNotesChange }: NotesEditorProps) => {
  return (
    <Box mt={2} className="editor-container">
      <QuillEditor value={notes} onChange={onNotesChange} />
    </Box>
  )
}

export default NotesEditor
