import { getToken } from 'utils/authCookies'

// import { CreateTagParams } from './index.types'

const tagBaseUrl = `${process.env.REACT_APP_API_BASE_PATH}/tag`

// export const createTag = async (createData: CreateTagParams) => {
//   const token = await getToken()
//   try {
//     const res = await fetch(tagBaseUrl, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: ` Bearer ${token}`,
//       },
//       body: JSON.stringify(createData),
//     })

//     const { data } = await res.json()
//     if (res.ok) {
//       return { status: 'successful', data }
//     }
//     return { status: 'failed' }
//   } catch (error) {
//     console.error(error)
//   }
// }

export const getTags = async (entity: string, orgId: number) => {
  const token = await getToken()
  try {
    const res = await fetch(
      `${tagBaseUrl}?orgId=${orgId}&entity=${entity.toUpperCase()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: ` Bearer ${token}`,
        },
      }
    )

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error(error)
  }
}
