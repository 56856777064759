import { useEffect } from 'react'

import { Box, InputAdornment, Button } from '@mui/material'
import { useFormikContext } from 'formik'

import Card from 'components/card'
import Label from 'components/forms/Label'
import CustomTextField from 'pages/dashboard/components/CustomTextField'

import ProductVariants from './Variants'
import { ProductDetailFormValues } from '../pages/AddProductPage'

interface ProductPricingProps {
  productId?: number
  variantsAdded: boolean
  onVariantAdd: (val: boolean) => void
}

const ProductPricing: React.FC<ProductPricingProps> = ({
  variantsAdded,
  onVariantAdd,
}) => {
  const formik = useFormikContext<ProductDetailFormValues>()

  useEffect(() => {
    if (variantsAdded) {
      formik.values.variants.push({
        type: '',
        name: '',
        price: formik.values.price,
        quantity: formik.values.quantity,
        costPerUnit: formik.values.costPerUnit,
        discountedPrice: '',
        discountPercentage: '',
        offerValidTill: [null, null],
      })
      formik.setFieldValue('price', 0)
      formik.setFieldValue('quantity', '')
      formik.setFieldValue('costPerUnit', '')
      formik.setFieldValue('discountedPrice', '')
      formik.setFieldValue('discountPercentage', '')
      formik.setFieldValue('offerValidTill', [null, null])
    }
  }, [variantsAdded])

  return (
    <div className="mb-5">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mr-4"
      >
        <h2 className="text-lg font-semibold ml-4">Pricing & Stock</h2>
      </Box>

      <Card>
        {!variantsAdded ? (
          <div className="grid grid-cols-3 gap-4">
            <div>
              <Label text="Selling Price" htmlFor="price" />
              <CustomTextField
                type="number"
                name="price"
                min={0}
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.price) && formik.touched.price}
                helperText={formik.touched.price && formik.errors.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Label text="Quantity In Stock" htmlFor="quantity" />
              <CustomTextField
                type="number"
                name="quantity"
                min={0}
                value={formik.values.quantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.quantity) && formik.touched.quantity
                }
                helperText={formik.touched.quantity && formik.errors.quantity}
              />
            </div>
            <div>
              <Label text="Cost Per Unit" htmlFor="costPerUnit" />
              <CustomTextField
                type="number"
                name="costPerUnit"
                min={0}
                value={formik.values.costPerUnit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.costPerUnit) &&
                  formik.touched.costPerUnit
                }
                helperText={
                  formik.touched.costPerUnit && formik.errors.costPerUnit
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Button
                variant="outlined"
                size="small"
                onClick={() => onVariantAdd(true)}
              >
                Add more variants
              </Button>
            </div>
          </div>
        ) : (
          <ProductVariants onVariantAdd={onVariantAdd} />
        )}
      </Card>
    </div>
  )
}

export default ProductPricing
