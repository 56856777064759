import { useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { changePassword } from 'services/auth'

const FormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .min(8, 'Password must be 8+ characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$*_^&])[A-Za-z\d@#$*_^&]{8,}$/,
      'Password must be 8+ characters with at least one uppercase, one lowercase, one digit, and one special character (@, #, $, *, ^, &)'
    )
    .required('New password is required'),
  confirmNewPassword: Yup.string()
    .min(1, 'Password confirmation is required')
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords do not match')
    .required('Confirm password is required'),
})

const Settings = (): JSX.Element => {
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const { showSnackbar } = useSnackbar()

  const handleClickOpen = (): void => {
    if (user?.id) {
      Amplitude.trackEvent('ACCOUNT_CHANGEPASSWORD_CLICKED', {
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email,
      })
    }
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      if (user?.id) {
        Amplitude.trackEvent('ACCOUNT_CHANGEPASSWORD_SAVE_CLICKED', {
          userId: user.id,
          userName: `${user.firstName} ${user.lastName}`,
          userEmail: user.email,
        })
      }
      const result = await changePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      if (result?.status === 'success')
        showSnackbar('Password changed successfully', 'success')

      handleClose()
    },
  })

  return (
    <div className=" mb-5">
      <h2 className="text-lg font-semibold ml-4">Account Settings</h2>
      <Card>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item md={6}>
            <Label text="First Name" color="secondary" />
            <Typography fontWeight={700}>{user?.firstName ?? 'N/A'}</Typography>
          </Grid>
          <Grid item md={6}>
            <Label text="Last Name" color="secondary" />
            <Typography fontWeight={700}>{user?.lastName ?? 'N/A'}</Typography>
          </Grid>
          <Grid item md={6}>
            <Label text="Email" color="secondary" />
            <Typography fontWeight={700}>{user?.email}</Typography>
          </Grid>
        </Grid>
        <Button
          variant="text"
          size="small"
          sx={{
            color: 'text.primary',
            borderRadius: 0,
            p: 0,
            borderBottom: '1px solid',
            textTransform: 'none',
            fontWeight: 700,
            mt: 1.5,
          }}
          onClick={handleClickOpen}
        >
          Change Password
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          PaperProps={{ component: 'form', onSubmit: formik.handleSubmit }}
        >
          <DialogTitle fontWeight={600}>Change Password</DialogTitle>
          <DialogContent>
            <div>
              <Label
                text="Current Password"
                htmlFor="currentPassword"
                color="secondary"
              />
              <CustomTextField
                id="currentPassword"
                name="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                }
                helperText={
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword
                }
              />
            </div>
            <div>
              <Label
                text="New Password"
                htmlFor="newPassword"
                color="secondary"
              />
              <CustomTextField
                id="newPassword"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />
            </div>
            <div>
              <Label
                text="Confirm New Password"
                htmlFor="confirmNewPassword"
                color="secondary"
              />
              <CustomTextField
                id="confirmNewPassword"
                name="confirmNewPassword"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmNewPassword &&
                  Boolean(formik.errors.confirmNewPassword)
                }
                helperText={
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleClose}
              size="small"
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="small"
              sx={{ textTransform: 'none' }}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Submitting' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </div>
  )
}

export default Settings
