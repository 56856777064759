import { FC, useEffect, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { updateProduct } from 'services/products'
import { Product } from 'services/products/index.types'

import VariantsTable from './VariantsTable'

type VariantType = {
  id?: number
  type: string
  name: string
  price: number | string
  quantity: number | string
  cost: number | string
}

interface VariantDetailsProps {
  variants?: VariantType[]
  productId: number
  onUpdate: (data?: Product) => void
  onEditCancel: () => void
  edit: boolean
}

const VariantsDetails: FC<VariantDetailsProps> = ({
  variants = [],
  productId,
  onUpdate,
  edit,
  onEditCancel,
}) => {
  const [rows, setRows] = useState<VariantType[]>([])
  const [error, setError] = useState<{ message: string } | null>(null)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setRows(variants)
  }, [variants])

  const onRowsChange = (arr: VariantType[]): void => {
    setRows(arr)
  }

  const handleSave = async (): Promise<void> => {
    if (rows.length == 0 && variants.length == 0) {
      return
    }

    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_VARIANT_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }

    const hasEmptyFields = rows.some((row) => {
      if (row.name.trim() === '' || row.price === '' || row.type === '') {
        return true
      }
      return false
    })

    if (hasEmptyFields) {
      setError({ message: '*Please fill in the required fields.' })
      return
    }

    const data = rows.map((el) => ({
      ...el,
      quantity: el.quantity === '' ? undefined : el.quantity,
      cost: el.cost === '' || !el.cost ? undefined : el.cost,
    }))

    setIsSubmitting(true)
    const res = await updateProduct({
      productId,
      dataUpdate: { variants: data },
    })

    if (res.status === 'successful') {
      showSnackbar('Variants updated successfully.', 'success')
      onUpdate(res.data)
    }
    setIsSubmitting(false)
    onEditCancel()
    setError(null)
  }

  const handleCancel = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_VARIANT_CANCEL_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setRows(variants)
    onEditCancel()
    setError(null)
  }

  return (
    <div>
      {error && <p className=" text-xs text-red-500 mb-2">{error.message}</p>}
      <Box>
        {rows.length === 0 && !edit && (
          <Typography>No variants available</Typography>
        )}
        <VariantsTable
          edit={edit}
          rowData={rows}
          onRowAdd={onRowsChange}
          onRowRemove={onRowsChange}
        />
      </Box>
      {edit && (
        <div className="mt-4">
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none', mr: 2 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={handleSave}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default VariantsDetails
