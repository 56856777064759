import { useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  styled,
  TextField,
  Typography,
} from '@mui/material'

import Amplitude from 'lib/amplitude'
import Footer from 'pages/home/components/Footer'
import { earlyRequestAccess } from 'services/auth'
import theme from 'styles/muiTheme'

const Pricing = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const plans = [
    {
      title: 'FREE',
      description: 'For getting started',
      price: '₹ 0',
      features: [
        '1 Online store',
        '1 Staff account',
        'Unlimited Products',
        'Mobile-Optimized Store',
        'Customer Notifications',
      ],
    },
    {
      title: 'PLUS',
      description: 'For small businesses',
      price: '₹ 999',
      features: [
        '1 Online store',
        '3 Staff accounts',
        'Custom Domain Integration',
        'In depth Business Analytics',
        'Instagram Integration',
      ],
    },
    {
      title: 'GROWTH',
      description: 'As your business scales',
      price: '₹ 2499',
      features: [
        '3 Online stores',
        '10 Staff accounts',
        'Advanced Store Customization',
        'Built-in Ad Campaign Creator',
        'Mobile App',
      ],
    },
  ]

  // Email validation function
  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  // Handle email input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setError('')
    setSuccessMessage('')
  }

  // Handle form submission
  const handleSubmit = async () => {
    Amplitude.trackEvent('REQUEST_INVITE_CLICKED', {
      email: email,
    })

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.')
      return
    }

    setIsSubmitting(true)

    try {
      const res = await earlyRequestAccess({
        email,
      })

      if (res && res.status === 'success') {
        setSuccessMessage(
          'Your request has been received. Our team will get back to you in less than 24 hours.'
        )
        setEmail('')
      } else {
        setError('Something went wrong. Please try again.')
      }
    } catch (error) {
      setError('Something went wrong. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const PlanCard = styled(Card)(({ theme }) => ({
    borderRadius: '8px',
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    '&:hover': {
      boxShadow: `0px 4px 50px ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  }))

  return (
    <div className="mt-32">
      <div className="text-3xl md:text-4xl text-center font-bold">
        <p className="mb-1">Start with the Best Fit</p>
        <p>Change Plans as Your Business Grows!</p>
      </div>
      <Container maxWidth="md" className="mb-4">
        <div className="flex justify-evenly mt-16 space-x-4">
          <TextField
            className="flex-1"
            id="request-invite-email-input"
            label="Enter your email address"
            variant="outlined"
            size="small"
            value={email}
            onChange={handleChange}
            error={!!error}
            helperText={error}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Request Invite'}
          </Button>
        </div>
        {successMessage && (
          <Typography variant="body2" color="green" className="mt-4">
            {successMessage}
          </Typography>
        )}
      </Container>
      <Container maxWidth="lg" className="py-10">
        <p className="text-center text-2xl font-bold mb-8">OUR PLANS</p>
        <Box className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {plans.map((plan, index) => (
            <PlanCard key={index} className="hover:shadow-lg">
              <CardContent className="text-center">
                <Box
                  component="p"
                  className="text-lg font-bold"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {plan.title}
                </Box>
                <p className="text-xs text-slate-400 font-bold">
                  {plan.description}
                </p>
                <p className="text-4xl my-8 font-bold">
                  {plan.price}
                  <span className="text-xs text-slate-400">/month</span>
                </p>
                <Box className="my-8 border-t-2 border-gray-200 w-3/4 mx-auto"></Box>
                <p className="text-xs font-bold text-slate-400 mb-4">
                  PLAN PERKS
                </p>
                <ul className="list-none text-center px-4">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="mb-2 text-sm">
                      {feature}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </PlanCard>
          ))}
        </Box>
      </Container>
      <Box
        sx={{
          background: 'rgba(0, 137, 123, 0.1)',
          width: '100%',
        }}
      >
        <Footer />
      </Box>
    </div>
  )
}

export default Pricing
