import { useEffect, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { postcodeValidator } from 'postcode-validator'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { AddressType } from 'pages/dashboard/components/AddressInput'
import { createAddress, updateAddress } from 'services/customers'
import { Address as CustomerAddress } from 'services/customers/index.type'
import { getCountryCode } from 'utils/countries'

import Address from './Address'

const phoneNumberUtil = PhoneNumberUtil.getInstance()

const AddressFormSchema = z
  .object({
    country: z.string().min(1, '*Country is required.').max(100).trim(),
    firstName: z.string().min(1, '*First Name is required.').trim(),
    lastName: z.string().optional(),
    addressLine1: z.string().min(1, '*Please select address.').trim(),
    addressLine2: z.string().min(1, '*Address is required.').trim(),
    city: z.string().min(1, '*Please select city name').trim(),
    state: z.string().min(1, '*State is required.').trim(),
    pinCode: z.string().min(1, '*Pin code is required.').trim(),
    countryCode: z.string().min(1, '*Please select Country Code'),
    phoneNo: z.string().min(1, '*Please provide correct phone no.').trim(),
  })
  .refine(
    (val) => {
      //Checking phone number according to country format
      if (!val.countryCode && !val.phoneNo) return true

      const fullPhoneNumber = `${val.countryCode}${val.phoneNo}`
      try {
        const phoneNumber = phoneNumberUtil.parse(fullPhoneNumber)
        return phoneNumberUtil.isValidNumber(phoneNumber)
      } catch (error) {
        return false
      }
    },
    {
      path: ['phoneNo'],
      message: '*Invalid phone number',
    }
  )
  .refine(
    (val) => {
      // Checking postal code according to country format
      if (!val.country || !val.pinCode) return false
      const postalCode = val.pinCode
      const postalCodeCountry = getCountryCode(val.country)
      try {
        return postalCodeCountry
          ? postcodeValidator(postalCode, postalCodeCountry)
          : false
      } catch (error) {
        return false
      }
    },
    {
      path: ['pinCode'],
      message: '*Invalid format',
    }
  )

export type AddressFormType = z.infer<typeof AddressFormSchema>

interface AddressDialogProps {
  onClose: () => void
  address?: CustomerAddress
  customerId: number
  onUpdate?: () => void
}

const AddressDialog = ({
  onClose,
  address,
  customerId,
  onUpdate,
}: AddressDialogProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<AddressType>()
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()

  const phone: { countryCode?: string; phoneNo?: string } = {}
  if (address) {
    const phone_number = phoneNumberUtil.parse(address?.phoneNo)
    phone.countryCode = '+' + phone_number.getCountryCode()?.toString()
    phone.phoneNo = phone_number.getNationalNumber()?.toString()
  }

  const defaultValues = {
    country: address?.country ?? '',
    firstName: address?.firstName ?? '',
    addressLine1: address?.addressLine1 ?? '',
    addressLine2: address?.addressLine2 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    pinCode: address?.pinCode ?? '',
    countryCode: phone.countryCode ?? '',
    phoneNo: phone.phoneNo ?? '',
    lastName: address?.lastName ?? '',
  }
  const methods = useForm({
    resolver: zodResolver(AddressFormSchema),
    defaultValues: defaultValues,
  })

  const handleAddress = (data: AddressType): void => {
    setSelectedAddress(data)
  }

  useEffect(() => {
    if (address)
      setSelectedAddress({
        address1: address.addressLine1,
        addressUrl: address.addressUrl,
        city: address.city,
        latitude: address.latitude,
        longitude: address.longitude,
        state: address.state,
        country: address.country,
        postalCode: address.pinCode,
      })
  }, [address])

  const submitHandler: SubmitHandler<AddressFormType> = async (values) => {
    if (organizationId) {
      Amplitude.trackEvent('CUSTOMER_ADDADDRESS_ADD_CLICKED', {
        orgId: organizationId,
      })
    }
    if (selectedAddress && selectedAddress.country !== values.country) {
      methods.setError('addressLine1', {
        type: 'custom',
        message: '*Not valid for this country: ' + values.country,
      })
      setIsSubmitting(false)
      return
    }

    if (selectedAddress && selectedAddress.state !== values.state) {
      methods.setError('addressLine1', {
        type: 'custom',
        message: '*Not valid for this state: ' + values.state,
      })
      setIsSubmitting(false)
      return
    }

    if (
      selectedAddress &&
      selectedAddress?.city &&
      selectedAddress.city !== values.city
    ) {
      methods.setError('addressLine1', {
        type: 'custom',
        message: '*Not valid for this city: ' + values.city,
      })
      setIsSubmitting(false)
      return
    }

    if (
      selectedAddress &&
      selectedAddress.postalCode &&
      selectedAddress.postalCode !== values.pinCode
    ) {
      methods.setError('pinCode', {
        type: 'custom',
        message: '*Not valid for this city',
      })
      setIsSubmitting(false)
      return
    }

    const addressPhoneNo =
      values.countryCode && values.phoneNo
        ? values.countryCode + values.phoneNo
        : ''

    const addressData = {
      firstName: values.firstName,
      lastName: values.lastName,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      state: values.state,
      country: values.country,
      pinCode: values.pinCode,
      phoneNo: addressPhoneNo,
      latitude: selectedAddress?.latitude,
      longitude: selectedAddress?.longitude,
      addressUrl: selectedAddress?.addressUrl,
    }

    setIsSubmitting(true)
    let result:
      | {
          status: string
          message?: string
        }
      | undefined
    if (address && address.id) {
      result = await updateAddress(address.id, { ...addressData })
    } else {
      result = await createAddress({ ...addressData, customerId })
    }

    setIsSubmitting(false)

    if (result?.status === 'successful') {
      address
        ? showSnackbar('Address Updated.', 'success')
        : showSnackbar('New Address Created.', 'success')
      onUpdate?.()
    } else if (result?.status === 'failed') {
      showSnackbar(result.message ?? ' Please try again later', 'error')
    }
    onClose()
  }

  return (
    <FormProvider {...methods}>
      <Dialog
        open
        onClose={onClose}
        PaperProps={{
          component: 'form',
          onSubmit: methods.handleSubmit(submitHandler),
          sx: { backgroundColor: '#f5f5f5' },
        }}
      >
        <DialogTitle>
          {address ? 'Update Address' : 'Create New Address'}
        </DialogTitle>
        <DialogContent>
          <Address onAddressSelect={handleAddress} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting' : address ? 'update' : 'add'}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export default AddressDialog
