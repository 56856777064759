import { FC } from 'react'

import { Box, styled } from '@mui/material'
import { DataGrid, DataGridProps } from '@mui/x-data-grid'

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-container--top [role=row]': {
    backgroundColor: theme.palette.accents?.darkGrayAccent,
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    backgroundColor: theme.palette.accents?.darkGrayAccent,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle ': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-row': {
    backgroundColor: theme.palette.accents?.grayAccent,
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.accents?.lightGrayAccent,
  },
}))

export const DataGridTable: FC<DataGridProps> = (props) => {
  return (
    <Box
      sx={{
        minHeight: 400,
        width: { sm: 'calc(100vw - 280px)' },
        height: 'calc(100vh - 140px)',
      }}
    >
      <StyledDataGrid {...props} />
    </Box>
  )
}
