import React, { useState } from 'react'

import { InputAdornment, TableCell, TableRow, TextField } from '@mui/material' // Import Button for Cancel

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import EditButton from 'pages/dashboard/components/EditButton'
import { updateStock } from 'services/stock'

import { Variant } from './StockDetailsTable'

interface StockTableRowProps {
  variant: Variant
  setVariants: React.Dispatch<React.SetStateAction<Variant[]>>
}

const StockTableRow: React.FC<StockTableRowProps> = ({
  variant,
  setVariants,
}) => {
  const [editing, setEditing] = useState(false)
  const { organizationId } = useAuth()

  const [editedVariant, setEditedVariant] = useState<Partial<Variant>>({
    cost: variant.cost,
    quantity: variant.quantity,
  })

  const [originalVariant, setOriginalVariant] = useState<Partial<Variant>>({
    cost: variant.cost,
    quantity: variant.quantity,
  })

  const handleEditClick = () => {
    if (organizationId) {
      Amplitude.trackEvent('STOCK_ITEM_EDIT_CLICKED', {
        stockId: variant.stockId,
        orgId: organizationId,
      })
    }
    setEditing(true)
    setOriginalVariant({
      cost: variant.cost,
      quantity: variant.quantity,
    })
  }

  const handleSaveClick = async () => {
    if (variant.stockId && organizationId) {
      Amplitude.trackEvent('STOCK_ITEM_SAVED_CLICKED', {
        stockId: variant.stockId,
        orgId: organizationId,
      })
      const updatedData = {
        stockId: variant.stockId,
        organizationId: organizationId,
        cost: Number(editedVariant.cost),
        quantity: Number(editedVariant.quantity),
      }

      await updateStock({ updatedStockData: updatedData })

      setVariants((prevVariants) =>
        prevVariants.map((v) =>
          v.id === variant.id
            ? {
                ...v,
                cost: String(updatedData.cost),
                quantity: updatedData.quantity,
              }
            : v
        )
      )

      setEditing(false)
    } else {
      throw new Error('Error || undefined.')
    }
  }

  const handleCancelClick = () => {
    setEditedVariant(originalVariant)
    setEditing(false)
  }

  const handleChange = (field: keyof Variant, value: string | number) => {
    setEditedVariant((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  return (
    <TableRow>
      {variant.name ? <TableCell>{variant.name}</TableCell> : null}
      <TableCell>
        {editing ? (
          <TextField
            type="number"
            value={editedVariant.cost}
            onChange={(e) => handleChange('cost', e.target.value)}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
        ) : variant.cost !== 'N/A' ? (
          '₹ ' + variant.cost
        ) : (
          'N/A'
        )}
      </TableCell>
      <TableCell>
        {editing ? (
          <TextField
            type="number"
            value={String(editedVariant.quantity)}
            onChange={(e) => handleChange('quantity', e.target.value)}
            variant="outlined"
            size="small"
          />
        ) : (
          variant.quantity
        )}
      </TableCell>
      <TableCell>{variant.sold}</TableCell>
      <TableCell align="right">
        {editing ? (
          <>
            <div className="flex flex-row justify-end">
              <div>
                <EditButton buttonText="Save" onClick={handleSaveClick} />
              </div>
              <div className="ml-2">
                <EditButton buttonText="Cancel" onClick={handleCancelClick} />
              </div>
            </div>
          </>
        ) : (
          <EditButton buttonText="Edit" onClick={handleEditClick} />
        )}
      </TableCell>
    </TableRow>
  )
}

export default StockTableRow
