import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import CardMUI from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { format } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { getOrderById, updateStatus } from 'services/orders'
import { Order, ShippingAddress } from 'services/orders/index.type'
import { AddressJsonType } from 'services/organization/index.types'
import { dateTimeFormat, shortDateFormat } from 'utils/dateFormat'

import srcImg from '../../../../../assests/img/sunrise-placeholder.png'
import InvoiceViewDialog from '../components/GenerateInvoice'
import OrderNotesDetail from '../components/NotesDetail'
import OrderTimeLine from '../components/Timeline'

const statusList = [
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Shipped', value: 'SHIPPED' },
  { label: 'Delivered', value: 'DELIVERED' },
  { label: 'Return Initiated', value: 'RETURN_INITIATED' },
  { label: 'Refunded', value: 'REFUNDED' },
]

const OrderDetailsPage = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState<string | null>(null)
  const [order, setOrder] = useState<Order | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [updatePage, setUpdatePage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { showSnackbar } = useSnackbar()
  const { organization } = useAuth()
  const [generateInvoice, setGenerateInvoice] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const [openCancelDialog, setOpenCancelDialog] = useState(false)

  const openMenu = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleDialogClose = (): void => {
    setOpen(false)
  }

  const handleOpenCancelDialog = (): void => {
    setOpenCancelDialog((prev) => !prev)
    setAnchorEl(null)
    setStatus('CANCELLED')
  }

  const handleCancelDialogClose = (): void => {
    setOpenCancelDialog(false)
    setStatus(null)
  }

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setStatus(e.target.value)
  }

  const openInvoiceDialog = (): void => {
    setGenerateInvoice(true)
    handleClose()
  }
  const handleReasonChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCancellationReason(e.target.value)
  }

  useEffect(() => {
    const get = async (): Promise<void> => {
      setIsLoading(true)
      const result = orderId
        ? await getOrderById(orderId)
        : { status: 'failed' }

      if (result?.status === 'successful' && result.data) {
        setOrder(result.data)
      } else if (result?.status === 'failed' && result.message) {
        setError(result.message)
      }
      setIsLoading(false)
      setUpdatePage(false)
    }
    get()
  }, [orderId, updatePage])

  const handleStatusSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()
    setIsSubmitting(true)
    if (status) {
      const result = orderId
        ? await updateStatus(orderId, {
            status,
            description:
              cancellationReason.trim() !== '' ? cancellationReason : undefined,
          })
        : { status: 'failed' }
      if (result?.status === 'successful') {
        showSnackbar('Status updated', 'success')
        setUpdatePage(true)
      } else if (result?.status === 'failed' && result.message) {
        showSnackbar(result.message, 'error')
      }
      setIsSubmitting(false)
    }
    setStatus(null)
    handleDialogClose()
    setOpenCancelDialog(false)
    setCancellationReason('')
  }

  if (isLoading) {
    return <p>Loading ...</p>
  }

  if (error || !order) {
    return (
      <Typography textAlign={'center'} fontWeight={700}>
        {error}
      </Typography>
    )
  }

  const orderDate = dateTimeFormat(order.createdAt)

  const orderStages = order.orderStatuses
    .map((el) => ({
      label: el.status.split('_').join(' '),
      date: shortDateFormat(el.createdAt),
    }))
    .reverse()

  const formatAddress = (shippingAddress: ShippingAddress): string => {
    if (!shippingAddress) return ''
    return `${shippingAddress.address}, ${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.pinCode}`
  }

  const customer = {
    id: order.customer.id,
    name: order.customer.firstName
      ? order.customer.firstName +
        (order.customer.lastName ? ' ' + order.customer.lastName : '')
      : 'Not provided',
    contacts: order.customer.phoneNumber
      ? order.customer.phoneNumber + ', ' + order.customer.email
      : order.customer.email,
    shippingAddress: formatAddress(order.shippingAddress),
    billingAddress: formatAddress(order.customer.billingAddress),
  }

  const address: AddressJsonType | null =
    organization && organization.address
      ? JSON.parse(organization.address)
      : null

  const invoiceData = {
    billFrom: address && {
      address: address.addressLine1 + ', ' + address.addressLine2,
      city: address.city,
      state: address.state,
      pinCode: address.pinCode,
      country: address.country,
      name: organization ? organization.name : '',
      email: organization ? organization.email : '',
    },
    billTo: {
      name: order.customer.firstName
        ? order.customer.firstName + (order.customer.lastName ?? ' ')
        : '',
      address: order.customer.billingAddress.address,
      city: order.customer.billingAddress.city,
      state: order.customer.billingAddress.state,
      pinCode: order.customer.billingAddress.pinCode,
      country: order.customer.billingAddress.country,
      email: order.customer.email,
    },
    invoiceNumber: '01',
    invoiceDate: format(new Date(order.createdAt), 'dd-MM-yyyy'),
    amountDue: order.totalPrice,
    items: order.items.map((item) => ({
      name: item.name,
      unitPrice: item.price,
      color: item.color,
      size: item.size,
      quantity: item.quantity,
    })),
    totalPrice: order.totalPrice,
    taxRate: 0,
  }

  return (
    <div>
      <div className=" mx-5 flex justify-between items-center">
        <Box display={'flex'}>
          <button onClick={() => navigate('..')} className=" mr-2">
            <KeyboardBackspaceIcon />
          </button>
          <p className="text-xl font-bold">{`Order#${order.id}`}</p>
        </Box>
        <Button
          size="small"
          variant="outlined"
          sx={{
            paddingInline: '1rem',
            textTransform: 'none',
            fontSize: '0.8rem',
          }}
          id="action-button"
          aria-controls={open ? 'order-action' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Actions
        </Button>
        <Menu
          id="order-action"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'action-button',
          }}
        >
          <MenuItem onClick={openInvoiceDialog}>Generate Invoice</MenuItem>
          <MenuItem
            onClick={handleClickOpen}
            disabled={order.status === 'CANCELLED'}
          >
            Change Status
          </MenuItem>
          <MenuItem
            onClick={handleOpenCancelDialog}
            sx={{
              display: order.status === 'CANCELLED' ? 'none' : 'block',
            }}
          >
            Cancel Order
          </MenuItem>
        </Menu>
      </div>
      <Box className="m-5 p-5 rounded" sx={{ border: '1px solid lightgray' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Label text="Order Number" color="secondary" />
            <Typography fontWeight={700}>{order.id}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Total" color="secondary" />
            <Typography fontWeight={700}>
              {order.items.length} items, &#8377;{order.totalPrice}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Status" color="secondary" />
            <Typography fontWeight={700}>{order.status}</Typography>
            <ButtonBase
              sx={{
                color: 'text.secondary',
                fontSize: '0.75rem',
                fontWeight: '600',
                borderBottom: '1px solid',
                display: order.status === 'CANCELLED' ? 'none' : 'inline-flex',
              }}
              onClick={handleClickOpen}
            >
              Update
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label text="Created Date" color="secondary" />
            <Typography fontWeight={700}>{orderDate}</Typography>
          </Grid>
        </Grid>
      </Box>
      <div className="mb-4">
        <div className="mx-5">
          <SubTabHeader title="Timeline" />
        </div>
        <Card>
          <OrderTimeLine
            stages={orderStages}
            currentStage={orderStages.length - 1}
          />
        </Card>
      </div>
      <div className="mb-4">
        <div className="mx-5">
          <SubTabHeader title="Order Summary" />
        </div>
        <Card>
          {order.items.map((item, index) => (
            <CardMUI elevation={0} sx={{ padding: 0, mb: 2 }} key={index}>
              <CardContent>
                <Box
                  display={'flex'}
                  justifyContent={{ md: 'space-between' }}
                  alignItems={{ md: 'center' }}
                  flexDirection={{ xs: 'column', md: 'row' }}
                  rowGap={3}
                >
                  <Box flex={1} display={'flex'} alignItems={'center'}>
                    <img
                      src={item.media ? item.media[0] : srcImg}
                      width={'70px'}
                      height={'70px'}
                      alt="product img"
                    />
                    <div className=" ml-5">
                      <ButtonBase
                        sx={{
                          fontWeight: '700',
                          borderBottom: '1px solid',
                          mb: 1.5,
                        }}
                        onClick={() =>
                          navigate(`/dashboard/products/${item.productId}`)
                        }
                      >
                        {item.name}
                      </ButtonBase>
                      {item.color && (
                        <div className="flex justify-between items-center flex-wrap">
                          <Chip label={item.color} size="small" />
                        </div>
                      )}
                      {item.size && (
                        <div className="flex justify-between items-center flex-wrap">
                          <Chip label={item.size} size="small" />
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box
                    flex={1}
                    display={'flex'}
                    justifyContent={{ xs: 'flex-end', md: 'space-between' }}
                    alignItems={'center'}
                    columnGap={{ xs: 10, md: 0 }}
                  >
                    <Typography fontWeight={700}>
                      {item.quantity} &times; &#8377;{item.price}
                    </Typography>
                    <Typography fontWeight={700}>
                      &#8377;{item.quantity * item.price}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </CardMUI>
          ))}
          <div className=" flex justify-end items-center px-4 gap-12">
            <Typography fontWeight={700}>Total</Typography>
            <Typography fontWeight={700}>&#8377;{order.totalPrice}</Typography>
          </div>
        </Card>
      </div>
      <div className="mb-4">
        <div className="mx-5">
          <SubTabHeader title="Customer Details" />
        </div>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Label text="Name" color="secondary" />
              <ButtonBase
                sx={{
                  fontWeight: '700',
                  borderBottom: '1px solid',
                }}
                onClick={() => navigate(`/dashboard/customers/${customer.id}`)}
              >
                {customer.name}
              </ButtonBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Contact" color="secondary" />
              <Typography fontWeight={700}>{customer.contacts}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Shipping Address" color="secondary" />
              <Typography fontWeight={700}>
                {customer.shippingAddress}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text="Billing Address" color="secondary" />
              <Typography fontWeight={700}>
                {customer.billingAddress}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </div>
      <OrderNotesDetail
        note={order.note}
        orderId={order.id}
        customerId={customer.id}
        organizationId={order.organizationId}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleStatusSubmit,
        }}
        fullWidth
      >
        <DialogTitle color={'primary'} fontWeight={700}>
          Change Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Label text="Current Status" color="secondary" />
            <Typography color={'text.primary'} fontWeight={700}>
              {order.status}
            </Typography>
            <div className="mt-5"></div>
            <Label text="New status" color="secondary" />
            <TextField
              SelectProps={{ style: { backgroundColor: 'white' } }}
              onChange={handleStatusChange}
              name={'status'}
              value={status}
              sx={{ marginTop: 0 }}
              fullWidth
              id="state"
              variant="outlined"
              margin="normal"
              size="small"
              select
            >
              {statusList.map((status) => (
                <MenuItem key={status.label} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </TextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Updating...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <InvoiceViewDialog
        invoiceData={invoiceData}
        open={generateInvoice}
        onClose={() => setGenerateInvoice(false)}
      />
      <div>
        <Dialog
          open={openCancelDialog}
          onClose={handleCancelDialogClose}
          PaperProps={{
            component: 'form',
            onSubmit: handleStatusSubmit,
          }}
          fullWidth
        >
          <DialogTitle color={'primary'}>Confirm Cancellation</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to cancel? Please provide a reason:</p>
            <TextField
              name="cancel-reason"
              variant="outlined"
              margin="normal"
              size="small"
              value={cancellationReason}
              onChange={handleReasonChange}
              multiline
              fullWidth
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDialogClose} color="primary">
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting || !cancellationReason.trim()}
            >
              {isSubmitting ? 'Updating...' : 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default OrderDetailsPage
