import { Customer } from 'services/customers/index.type'

export enum OrderStatusValue {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  RETURN_INITIATED = 'RETURN_INITIATED',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
}

export const statusColors: { [key in OrderStatusValue]: string } = {
  [OrderStatusValue.CREATED]: '#F88379', // Peach
  [OrderStatusValue.PROCESSING]: '#90CAF9', // Light Blue
  [OrderStatusValue.SHIPPED]: '#42A5F5', // Medium Blue
  [OrderStatusValue.DELIVERED]: '#66BB6A', // Light Green
  [OrderStatusValue.RETURN_INITIATED]: '#FFEE58', // Light Yellow
  [OrderStatusValue.REFUNDED]: '#E57373', // Light Red
  [OrderStatusValue.CANCELLED]: '#D70040', // Red
}

export interface ShippingAddress {
  address: string
  city: string
  state: string
  country: string
  pinCode: string
}

export interface Order {
  id: number
  firstName: string
  lastName: string
  totalPrice: number
  createdAt: string
  updatedAt: string
  status: string
  customerId: number
  paymentId: number
  shippingAddress: ShippingAddress
  phoneNo: string
  extraAddressInfo: Record<string, unknown>
  note: string
  organizationId: number
  items: {
    productId: number
    name: string
    variantId?: number
    color?: string
    size?: string
    media?: string[]
    quantity: number
    price: number
    description?: string
  }[]
  customer: Customer
  orderStatuses: OrderStatus[]
}

export interface OrderQueryParams {
  orgId: number
  cursor?: number
  pageSize?: number
  direction?: 'backward' | 'forward' | ''
  sortField?: string
  sortDirection?: 'asc' | 'desc' | ''
  filterField?: string
  filterValue?: string
  filterOperator?: string
  filterLogicOperator?: 'AND' | 'OR' | ''
}

export interface OrderStatus {
  id: number
  status: string
  description: string
  createdAt: string
}

export interface UpdateOrderParams {
  note?: string
  customerId: number
  organizationId: number
}
