import React, { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Snackbar from '@mui/material/Snackbar'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'

interface FormData {
  email: string
  password: string
}

interface LoginFormProps {
  onForgotPassword: () => void
}

const FormSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Invalid email'),
  password: z
    .string()
    .min(1, 'Password is required')
    .min(8, 'Password must have at least 8 characters'),
})

const LoginForm: React.FC<LoginFormProps> = ({ onForgotPassword }) => {
  const { signIn } = useAuth()
  const navigate = useNavigate()

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const registerEmail = register('email')
  const registerPassword = register('password')

  const onSubmit: SubmitHandler<FormData> = async (values) => {
    if (values) {
      Amplitude.trackEvent('GET_STARTED_LOGIN_CLICKED', {
        userEmail: values.email,
      })
    }
    try {
      await signIn(values.email, values.password)
      navigate('/dashboard/home')
    } catch (error) {
      console.error('Login failed:', error)
      setSnackbarMessage('User not found or invalid credentials')
      setSnackbarOpen(true)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <section>
          <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-24">
            <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
              <h2 className="text-center text-2xl font-bold leading-tight text-black">
                Sign in to your account
              </h2>
              <section className="mt-8">
                <div className="space-y-5">
                  <div>
                    <label
                      htmlFor="email"
                      className="text-base font-medium text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        onChange={registerEmail.onChange}
                        onBlur={registerEmail.onBlur}
                        name={registerEmail.name}
                        ref={registerEmail.ref}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                      />
                      {errors.email && (
                        <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                          *{errors.email?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="text-base font-medium text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        onChange={registerPassword.onChange}
                        onBlur={registerPassword.onBlur}
                        name={registerPassword.name}
                        ref={registerPassword.ref}
                        className="flex bg-white h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      {errors.password && (
                        <p className=" text-xs text-red-600 bg-red-200 px-2 py-1 rounded mt-1">
                          *{errors.password?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-2">
                    <Link
                      className="text-sm font-semibold text-black hover:underline"
                      onClick={onForgotPassword}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      sx={{ width: '100%' }}
                    >
                      Get started
                      <ArrowForwardIcon
                        className="ml-2"
                        component={ArrowForwardIcon}
                      />
                    </Button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default LoginForm
