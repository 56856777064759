import { FC } from 'react'

import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

interface AddButtonProps {
  buttonText: string
}
const AddButton: FC<AddButtonProps> = ({ buttonText }) => {
  const navigate = useNavigate()

  const handleNavigate = (): void => {
    if (location.pathname == '/dashboard/products/all') {
      navigate('/dashboard/products/new')
    } else {
      navigate('new')
    }
  }

  return (
    <Button
      variant="contained"
      size="small"
      onClick={handleNavigate}
      sx={{ textTransform: 'none' }}
    >
      {buttonText}
    </Button>
  )
}

export default AddButton
